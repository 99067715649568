import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { useSelectActiveTeam, useSelectNewRevenueSharesByTeamId, useSelectTeamHasBillingSettings } from 'app/src/selectors/team';
import { useSelectGamesByTeamId } from 'app/src/selectors/game';
import { getTeamNewRevenueShares } from 'app/src/epics/team';
import { listGamesByTeamId } from 'app/src/epics/game';
import { openModal } from 'app/src/actions/client';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';

import PencilIcon from 'shared/designTokens/icons/ui/small/PencilIcon';

import MessageBox, { MessageBoxIntents } from 'app/src/components/ui/MessageBox';
import GridContainer from 'app/src/components/ui/GridContainer';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import { STANDARD_REVENUE_SHARE, isMobile } from 'shared/vars';
import _ from 'shared/copy';

const RevenueSharingGameTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;

	font-weight: 700;
	color: ${props => props.theme.denimBlue};
`;

const RevenueSharingGameThunbnail = styled.div`
	position: relative;
	border-radius: 8px;
	background: ${props => (props.thumbnailUrl ? `url("${props.thumbnailUrl}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 46px;
	width: 46px;
`;

const StyledPencilIcon = styled(PencilIcon)`
	&:hover {
		cursor: pointer;
	}

	${({ $disabled }) => $disabled && `
		pointer-events: none;
		opacity: 0.5;
	`}
`;

const RevenueShareItem = styled.div`
	color: ${props => props.theme.grey5};

	${props => props.$shouldHighlight && `
		color: ${props.theme.pokiBlue};
		font-weight: bold;
	`}
`;

const categorizeRevenuShare = revenueShare => {
	if (Object.keys(revenueShare).length === 0 || Object.keys(revenueShare).every(key => revenueShare[key] === 0)) {
		return 'disabled';
	}

	const isStandard = Object.keys(revenueShare).every(key => revenueShare[key] === STANDARD_REVENUE_SHARE[key]);

	return isStandard ? 'standard' : 'custom';
};

const displayItem = (value, standardValue) => {
	const shouldDisplay = value !== 0 && value !== undefined;
	const shouldHighlight = value !== standardValue;

	return (
		shouldDisplay ? <RevenueShareItem $shouldHighlight={shouldHighlight}>{value}%</RevenueShareItem> : <RevenueShareItem>-</RevenueShareItem>
	);
};

const TeamSettingsRevenueSharingSubPage = props => {
	const { team } = props;

	const dispatch = useDispatch();

	const activeTeam = useSelectActiveTeam();
	const newRevenueShares = useSelectNewRevenueSharesByTeamId(team.id);
	const { data: games = [] } = useSelectGamesByTeamId(activeTeam?.id);
	const getTeamNewRevenueSharesStatus = useSelectApiStatus(getTeamNewRevenueShares.id);
	const teamHasBillingSettings = useSelectTeamHasBillingSettings(team);

	const [gamesWithRevenueShare, setGamesWithRevenueShare] = useState([]);

	useEffect(() => {
		dispatch(getTeamNewRevenueShares.fetch({ teamId: team.id }));
		dispatch(listGamesByTeamId.fetch({ teamId: team.id }));
	}, [dispatch, team.id]);

	// finds and categorizes the revenue share for each game
	// standard, custom and disabled
	useEffect(() => {
		if (games.length && newRevenueShares) {
			const g = games.map(game => {
				const share = newRevenueShares.find(r => r.game_id === game.id);

				const attributes = {
					ingame_poki_promotion: share?.ingame_poki_promotion,
					ingame_organic_landing: share?.ingame_organic_landing,
					developer_domain: share?.developer_domain,
					external: share?.external,
					poki_platform_display: share?.poki_platform_display,
				} || {};

				return {
					...game,
					revenue_share: share ? {
						category: categorizeRevenuShare(attributes),
						attributes,
					} : {
						category: 'disabled',
						attributes: {},
					},
				};
			});
			setGamesWithRevenueShare(g);
		}
	}, [newRevenueShares, games]);

	const handleChangeRevenueShare = game => {
		dispatch(openModal({ key: 'change-revenue-share', data: { game } }));
	};

	return (
		<GridContainer cols={1}>
			<Helmet key={`TeamSettingsRevenueSharingSubPage-${team.code}`}>
				<title>Revenue Sharing - {team.name} - Poki for Developers</title>
			</Helmet>
			{!teamHasBillingSettings && (
				<MessageBox
					title={_`billingSettingsRequired`}
					unclosable
					intent={MessageBoxIntents.NEGATIVE}
					description={(
						<span dangerouslySetInnerHTML={{ __html: _`billingSettingsRequiredDesc` }} />
					)}
				/>
			)}
			<Card
				title={_`revenueSharing`}
				noPadding
			>
				<Table
					items={gamesWithRevenueShare}
					isLoading={getTeamNewRevenueSharesStatus.pending}
					groupBy={item => item.revenue_share.category}
					groupOrder={['standard', 'custom', 'disabled']}
					groupByFormatter={category => {
						switch (category) {
							case 'standard':
								return 'Standard';
							case 'custom':
								return 'Custom';
							default:
								return 'Disabled';
						}
					}}
					columns={[
						{
							title: 'Game Title',
							content: ({ item }) => (
								<RevenueSharingGameTitle>
									<RevenueSharingGameThunbnail thumbnailUrl={getGameThumbnailUrl(item.thumbnail_url, 46)} />
									{item.title}
								</RevenueSharingGameTitle>
							),
						},
						{
							title: _`ingamePokiPromotion`,
							width: '120px',
							content: ({ item }) => {
								const { revenue_share: { attributes: { ingame_poki_promotion } } } = item;

								return displayItem(ingame_poki_promotion, STANDARD_REVENUE_SHARE.ingame_poki_promotion);
							},
						},
						{
							title: _`ingameOrganicLanding`,
							width: '120px',
							content: ({ item }) => {
								const { revenue_share: { attributes: { ingame_organic_landing } } } = item;

								return displayItem(ingame_organic_landing, STANDARD_REVENUE_SHARE.ingame_organic_landing);
							},
						},
						{
							title: _`developerDomain`,
							width: '120px',
							content: ({ item }) => {
								const { revenue_share: { attributes: { developer_domain } } } = item;

								return displayItem(developer_domain, STANDARD_REVENUE_SHARE.developer_domain);
							},
						},
						{
							title: _`external`,
							width: '120px',
							content: ({ item }) => {
								const { revenue_share: { attributes: { external } } } = item;

								return displayItem(external, STANDARD_REVENUE_SHARE.external);
							},
						},
						{
							title: _`pokiPlatformDisplay`,
							width: '120px',
							content: ({ item }) => {
								const { revenue_share: { attributes: { poki_platform_display } } } = item;

								return displayItem(poki_platform_display, STANDARD_REVENUE_SHARE.poki_platform_display);
							},
						},
						{
							title: isMobile ? _`actions` : '',
							width: '120px',
							content: ({ item }) => (
								<StyledPencilIcon onClick={() => handleChangeRevenueShare(item)} $disabled={!teamHasBillingSettings} />
							),
						},
					]}
				/>
			</Card>
		</GridContainer>
	);
};

export default TeamSettingsRevenueSharingSubPage;
