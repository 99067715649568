import React, { useEffect, useMemo } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { EMPTY, of, switchMap } from 'rxjs';
import moment from 'moment';
import lodash from 'lodash';
import styled from 'styled-components';

import { getTeamsOverviewFull, getTeamsOverviewTrend } from 'app/src/epics/data';
import { useSelectGamesByTeamId } from 'app/src/selectors/game';
import { useSelectAllTeamsList } from 'app/src/selectors/team';
import { useSelectPermissions } from 'app/src/selectors/user';
import { useSelectDataByEpic } from 'app/src/selectors/data';
import { getCountryByCode } from 'app/src/utils/countries';
import { listGamesByTeamId } from 'app/src/epics/game';
import { listAllTeams } from 'app/src/epics/team';
import { openModal } from 'app/src/actions/client';
import useURLState from 'app/src/hooks/useURLState';
import formatNumber from 'app/src/utils/formatNumber';
import checkPermissions from 'app/src/utils/checkPermissions';

import TeamNameWithFlag from 'app/src/components/ui/TeamNameWithFlag';
import GridContainer from 'app/src/components/ui/GridContainer';
import TinyChart from 'app/src/components/ui/charts/TinyChart';
import GamesTooltip from 'app/src/components/GamesTooltip';
import Container from 'app/src/components/ui/Container';
import Tooltip from 'app/src/components/ui/Tooltip';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import { dayMonthYearFormat, teamCustomerSegments, teamFlags } from 'shared/vars';
import _ from 'shared/copy';
import PlusIcon from 'shared/designTokens/icons/ui/small/PlusIcon';

const Data = styled.div`
	display: flex;
	flex-direction: column;
`;

const Stat = styled.div`
`;

const Team = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const TeamCustomerSegment = styled.div`
	font-size: 12px;
	color: ${props => props.theme.grey3};
`;

const NoFlags = styled.div`
	color: ${props => props.theme.grey5};
	border-bottom: 2px dotted ${props => props.theme.grey5};
`;

const perPage = 16;

const AdminTeamsPage = () => {
	const dispatch = useDispatch();

	const [endDate] = useURLState(
		'endDate',
		(() => {
			const date = new Date();
			date.setDate(date.getDate() - 1);
			date.setHours(23, 59, 59, 999);
			return date;
		})(),
	);

	const [startDate] = useURLState(
		'startDate',
		(() => {
			const date = new Date();
			date.setDate(date.getDate() - 30);
			date.setHours(0, 0, 0, 0);
			return date;
		})(),
	);

	const { rows: teamsOverview, total: totalTeams, included } = useSelectDataByEpic(getTeamsOverviewFull.id);
	const { rows: teamsOverviewTrend } = useSelectDataByEpic(getTeamsOverviewTrend.id);
	const listTeamsStatus = useSelectApiStatus(getTeamsOverviewFull.id);
	const allTeams = useSelectAllTeamsList();
	const permissions = useSelectPermissions();

	const [page, setPage] = useURLState('page', 1);
	const [sort, setSort] = useURLState('sort', { field: 'name', direction: 1 });
	const [activeFilters, setActiveFilters] = useURLState('teamsFilters', {});

	useEffect(() => {
		dispatch(listAllTeams.fetch());
	}, []);

	useEffect(() => {
		dispatch(getTeamsOverviewFull.fetch({
			from: startDate,
			to: endDate,
			page,
			perPage,
			sortField: sort.field,
			sortDirection: sort.direction,
			filters: activeFilters,
		}, ({ success$ }) => (
			success$.pipe(
				switchMap(({ payload: { result: { response } } }) => {
					const { rows } = response;
					const teamIds = lodash.uniq(lodash.map(rows, 'team_id'));

					if (teamIds.length === 0) return EMPTY;

					return of(
						getTeamsOverviewTrend.fetch({ teamIds, from: startDate, to: endDate }),
						...teamIds.map(id => listGamesByTeamId.fetch({ teamId: id })),
					);
				}),
			)
		)));
	}, [teamsOverview, startDate, endDate, page, perPage, sort, activeFilters]);

	const countriesWithCount = useMemo(() => {
		if (!allTeams || allTeams.length === 0) return {};
		const result = {};

		allTeams.forEach(team => {
			if (!result[team.primary_country]) {
				result[team.primary_country] = 1;
			} else {
				result[team.primary_country]++;
			}
		});

		return result;
	}, [allTeams]);

	const filters = useMemo(() => [
		{
			title: _`primaryCountry`,
			field: 'primary_country',
			type: 'multiselect',
			values: Object.keys(countriesWithCount).map(countryCode => ({
				value: countryCode,
				desc: getCountryByCode(countryCode)?.name || _`unknown`,
				count: countriesWithCount[countryCode],
			})),
		},
		{
			title: _`customerSegment`,
			field: 'customer_segment',
			type: 'multiselect',
			values: teamCustomerSegments,
		},
		{
			title: _`flags`,
			field: 'IN_SET:flags',
			type: 'multiselect',
			values: teamFlags,
		},
	], [countriesWithCount]);

	const daysOfTrendData = moment(endDate).diff(moment(startDate), 'days') + 1;

	return (
		<Container>
			<Helmet key="AdminTeamsPage">
				<title>Teams - Admin - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				<Card
					title={_`teams`}
					noPadding
					buttons={[
						{
							id: 'add-new-team',
							type: 'button',
							icon: PlusIcon,
							action: () => dispatch(openModal({ key: 'create-team' })),
							disabled: !checkPermissions(permissions, [['can_edit_all_teams']]),
							children: _`addNewTeam`,
						},
					]}
				>
					<Table
						title={_`teams`}
						isLoading={!listTeamsStatus.done}
						items={teamsOverview}
						page={page}
						perPage={perPage}
						setPagination={setPage}
						setSort={setSort}
						sortField={sort.field}
						sortDirection={sort.direction}
						totalItems={totalTeams}
						filters={filters}
						activeFilters={activeFilters}
						setActiveFilters={setActiveFilters}
						columns={[
							{
								title: _`teamName`,
								width: 'auto',
								content: ({ item }) => {
									const team = included.teams[item.team_id]?.attributes;

									return (
										<Team>
											<TeamNameWithFlag team={team} />
											<TeamCustomerSegment>{teamCustomerSegments.find(t => t.value === item.customer_segment)?.desc}</TeamCustomerSegment>
										</Team>
									);
								},
								sortField: 'name',
								defaultSortDirection: -1,
							},
							{
								title: _`games`,
								width: 'auto',
								sortField: 'games_count',
								defaultSortDirection: -1,
								content: ({ item }) => {
									const { data: games = [] } = useSelectGamesByTeamId(item?.team_id);

									return <GamesTooltip games={games} />;
								},
							},
							{
								title: _`flags`,
								width: 'auto',
								content: ({ item }) => {
									const flags = item.flags ? item.flags.split(',') : [];
									const flagsCount = flags.length;
									const flagsCountText = flagsCount === 1 ? `${flagsCount} flag` : `${flagsCount} flags`;

									return (
										flagsCount > 0 ? (
											<Tooltip
												text={flagsCountText}
												title={_`flags`}
												body={flags.map(flag => <div>{flag}</div>)}
											/>
										) : <NoFlags>0 flags</NoFlags>
									);
								},
							},
							{
								title: '30D Gameplays',
								width: 'max-content',
								sortField: 'gameplays',
								defaultSortDirection: -1,
								content: ({ item }) => {
									const dataOverTime = useMemo(() => (
										Array(daysOfTrendData).fill(null).map((__, i) => (
											teamsOverviewTrend.find(row => row.team_id === item.team_id && moment(startDate).diff(moment(row.date), 'days') === -i)?.gameplays || 0
										))
									), [teamsOverviewTrend, daysOfTrendData]);

									const gameplays = useMemo(() => (
										teamsOverview.find(row => row.team_id === item.team_id)?.gameplays
									), [teamsOverview]);

									return (
										<Data>
											<Stat>
												{formatNumber(gameplays, { shorten: true })}
											</Stat>
											<TinyChart
												type="area"
												width={80}
												height={15}
												data={dataOverTime}
											/>
										</Data>
									);
								},
							},
							{
								title: '30D Earnings',
								width: 'max-content',
								sortField: 'developer_earnings_eur',
								defaultSortDirection: -1,
								content: ({ item }) => {
									const propKey = 'developer_earnings_eur';

									const dataOverTime = useMemo(() => (
										Array(daysOfTrendData).fill(null).map((__, i) => (
											teamsOverviewTrend.find(row => row.team_id === item.team_id && moment(startDate).diff(moment(row.date), 'days') === -i)?.[propKey] || 0
										))
									), [teamsOverviewTrend, daysOfTrendData]);

									const earnings = useMemo(() => (
										teamsOverview.find(row => row.team_id === item.team_id)?.[propKey]
									), [teamsOverview]);

									return (
										<Data>
											<Stat $isZero={earnings <= 0.00}>
												{'€'}{formatNumber(earnings, { allowDecimals: true, shorten: true })}
											</Stat>
											<TinyChart
												type="area"
												width={80}
												height={15}
												data={dataOverTime}
											/>
										</Data>
									);
								},
							},
							{
								title: _`createdOn`,
								width: 'max-content',
								content: ({ item }) => {
									const createdAt = useMemo(() => (
										teamsOverview.find(row => row.team_id === item.team_id)?.created_at
									), [teamsOverview]);

									return (
										<>{moment(createdAt).format(dayMonthYearFormat)}</>
									);
								},
								sortField: 'created_at',
							},
						]}
					/>
				</Card>
			</GridContainer>
		</Container>
	);
};

export default AdminTeamsPage;
