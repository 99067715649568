import React, { useEffect, useState } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import { getMetrictestsByGameId } from 'app/src/epics/metrictests';
import { useSelectMetrictestsByGameId } from 'app/src/selectors/metrictests';
import { getGameById, listGamesByTeamId } from 'app/src/epics/game';
import { useSelectGameVersionsForPlaytests } from 'app/src/selectors/game';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { openModal } from 'app/src/actions/client';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';
import formatTime from 'app/src/utils/formatTime';

import PlusIcon from 'shared/designTokens/icons/ui/small/PlusIcon';

import GridContainer from 'app/src/components/ui/GridContainer';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';
import Tooltip from 'shared/components/Tooltip';

import { dayMonthTimeFormat } from 'shared/vars';
import _ from 'shared/copy';

const MetrictestPlayed = styled.div`
	color: ${props => props.theme.grey1};
`;

const UPDATE_INTERVAL = 30;

const GameMetrictestSubPage = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const activeTeam = useSelectActiveTeam();
	const versions = useSelectGameVersionsForPlaytests(game.id);
	const permissions = useSelectPermissions();

	const [page, setPage] = useState(1);

	const getMetrictestsByGameIdStatus = useSelectApiStatus(getMetrictestsByGameId.id);
	const { data: metrictests } = useSelectMetrictestsByGameId(game.id);

	const tableLoadingDone = (metrictests && metrictests.length > 0) || getMetrictestsByGameIdStatus.done;

	// Fetch a list of recordings when the page opens.
	useEffect(() => {
		dispatch(getMetrictestsByGameId.fetch({ gameId: game.id }));

		const i = setInterval(() => {
			dispatch(getMetrictestsByGameId.fetch({ gameId: game.id }));
			dispatch(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
			dispatch(getGameById.fetch(game.id));
		}, UPDATE_INTERVAL * 1000);

		return () => {
			clearInterval(i);
		};
	}, []);

	const onSuccessCallback = () => {
		dispatch(getGameById.fetch(game.id));
	};

	const handleStartNewMetrictest = () => {
		const { id, content_metadata: { release_status } = {} } = game;

		dispatch(openModal({
			key: 'start-new-metrictest',
			data: { gameId: id, releaseStatus: release_status, onSuccessCallback },
		}));
	};

	const canRequestRecordings = checkPermissions(permissions, [['can_edit_all_metrictests', 'can_edit_owned_metrictests']]);

	return (
		<>
			<Helmet key={`GameMetrictestSubPage-${game.id}-${activeTeam.code}`}>
				<title>Metrictest - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				<Card
					title="Metrictests"
					noPadding
					elevated
					buttons={[
						...((canRequestRecordings && [
							{
								id: 'start-new-metrictest',
								type: 'button',
								icon: PlusIcon,
								action: handleStartNewMetrictest,
								disabled: versions.length === 0,
								children: 'Start New Metrictest',
							},
						]) || []),
					]}
				>
					<Table
						title="Metrictests"
						isLoading={!tableLoadingDone}
						items={metrictests || []}
						setPagination={setPage}
						page={page}
						perPage={20}
						totalItems={(metrictests || []).length}
						autoPaging
						columns={[
							{
								title: _`when`,
								content: ({ item }) => (
									<MetrictestPlayed>
										{moment(item.created_at * 1000).format(dayMonthTimeFormat)}
									</MetrictestPlayed>
								),
							},
							{
								title: 'Status',
								content: ({ item }) => (
									<Tooltip
										arrow
										placement="top-end"
										maxWidth="250px"
										content={`In ${formatTime((item.stopped_at || (Date.now() / 1000)) - item.created_at, true, true)}`}
									>
										{item.stopped_at ? 'completed' : 'running'}
									</Tooltip>
								),
							},
							{
								title: _`version`,
								content: ({ item }) => {
									const version = game.versions.find(v => v.id === item.version_id);

									return (
										version ? (
											getVersionLabel(version)
										) : (
											'Unknown'
										)
									);
								},
							},
							{
								title: 'Gameplays',
								content: ({ item }) => item.gameplays,
							},
							{
								title: 'C2P',
								content: ({ item }) => (item.gameplays > 0 ? `${Math.round((item.started / item.gameplays) * 100)}%` : '-'),
							},
							{
								title: 'Engagement',
								content: ({ item }) => (item.gameplays > 0 ? formatTime(item.engagement, true, true) : '-'),
							},
						]}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default GameMetrictestSubPage;
