export const isThisShare = (share, { gameId, teamId, source, playedOn, specialCondition }) => {
	if (gameId) {
		return share.game_id === gameId
			&& (!source || share.trigger_source === source)
			&& (!playedOn || share.played_on === playedOn)
			&& (!specialCondition || share.special_condition === specialCondition);
	}

	if (teamId) {
		return share.team_id === teamId
			&& (!source || share.trigger_source === source)
			&& (!playedOn || share.played_on === playedOn)
			&& (!specialCondition || share.special_condition === specialCondition);
	}

	return false;
};

export const getShare = (options, revShares) => {
	if (!revShares) return undefined;
	const share = revShares.find(s => isThisShare(s, options));
	return share ? share.developer_share : undefined;
};

export const sharesContainNonZeroShares = revShares => {
	if (!revShares) return false;
	const share = revShares.find(s => s.developer_share > 0);
	return !!share;
};

export const isNonZeroShare = share => share && (share.ingame_poki_promotion > 0 || share.ingame_organic_landing > 0 || share.developer_domain > 0 || share.external > 0 || share.poki_platform_display > 0);

export const hasNonZeroShare = revShares => {
	if (!revShares) return false;
	return revShares.some(isNonZeroShare);
};
