import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Subject, ignoreElements, merge, of, switchMap, tap } from 'rxjs';
import styled from 'styled-components';

import { requestMetrictest } from 'app/src/epics/metrictests';
import { getGameById } from 'app/src/epics/game';
import { useSelectGameVersionsForPlaytests } from 'app/src/selectors/game';
import { uncaughtServerError } from 'app/src/actions/client';
import { registerModal } from 'app/src/modals';

import SelectInput from 'app/src/components/input/SelectInput';
import RadioInput from 'app/src/components/input/RadioInput';
import MultiSelectInput from 'app/src/components/input/MultiSelectInput';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';

import playtestAudiences from 'shared/utils/playtestAudiences';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: center;
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const DEVICE_MAP = {
	0: 'any',
	1: 'desktop',
	2: 'mobile',
};

const StartNewMetrictestModal = ({ data: { gameId, releaseStatus, onSuccessCallback } }) => {
	const dispatch = useDispatch();

	const versions = useSelectGameVersionsForPlaytests(gameId);

	// Games that are not released, or thave the 'no-link-release' status, will use the Mystery Game tile.
	// On the Mystery Game Tile you can target a specific audience.
	const enableAudienceTargeting = !releaseStatus || releaseStatus === 'not-released' || releaseStatus === 'no-link-release';
	const initialVersion = versions[0]?.value || '';

	const [exit$] = useState(new Subject());
	const [metrictestStarted, setMetrictestStarted] = useState(false);
	const [inspectorPassed, setInspectorPassed] = useState(versions[0]?.inspector_checklist?.['sdk-basics-init-sdk'] === 'PASSED');

	const { handleSubmit, control, watch } = useForm({
		defaultValues: {
			version: initialVersion,
			device: 0,
			audience: [],
		},
	});

	const versionID = watch('version');
	useEffect(() => {
		const version = versions.find(({ value }) => value === versionID);

		setInspectorPassed(version?.inspector_checklist?.['sdk-basics-init-sdk'] === 'PASSED');
	}, [versionID, versions]);

	const onSubmit = data => {
		const { version, device, audience = [] } = data;

		// Map audience to category ids
		const c = audience.reduce((acc, a) => {
			const { value } = playtestAudiences.find(({ desc }) => desc === a);
			return acc.concat(value);
		}, []);

		dispatch(requestMetrictest.fetch({
			gameId,
			data: { version_id: version, game_id: gameId, categories: c.join(','), device_category: DEVICE_MAP[device] },
		}, ({ success$, error$ }) => merge(
			success$.pipe(
				tap(() => onSuccessCallback()),
				ignoreElements(),
			),
			error$.pipe(
				switchMap(action => (
					of(
						uncaughtServerError({ action }),
						exit$.next(),
					)
				)),
			),
		)));

		setMetrictestStarted(true);
	};

	// Refresh game data when the window is focused, this might be the dev
	// coming back from the Inspector after doing the checklist.
	useEffect(() => {
		// Always refresh game data when the modal is opened to get the latest Inspector status.
		dispatch(getGameById.fetch(gameId));

		const listener = window.addEventListener('focus', () => {
			dispatch(getGameById.fetch(gameId));
		});
		return () => window.removeEventListener('focus', listener);
	}, []);

	const inspectorURL = `https://inspector.poki.dev/?game=poki-${versionID}`;

	return (
		<Modal
			exit$={exit$}
		>
			{metrictestStarted ? (
				<>
					<h2>Metrictest started</h2>
					<p>500 gameplays have been requested. We are looking for players. It might take a while for gameplays to appear here.</p>
					<Buttons>
						<Button onClick={() => exit$.next()}>
							{_`close`}
						</Button>
					</Buttons>
				</>
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					<h2>Start new metrictest</h2>
					<Controller
						control={control}
						name="version"
						render={({ field: { ref, ...fieldWithoutRef } }) => (
							<SelectInput
								{...fieldWithoutRef}
								label="Game version to metrictest"
								values={versions}
							/>
						)}
					/>
					<Controller
						control={control}
						name="device"
						render={({ field: { ref, ...fieldWithoutRef } }) => (
							<RadioInput
								{...fieldWithoutRef}
								renderAsRow
								label="Player's device"
								values={[
									{ value: 0, key: 'any', desc: 'Any' },
									{ value: 1, key: 'desktop', desc: 'Desktop' },
									{ value: 2, key: 'mobile', desc: 'Mobile/Tablet' },
								]}
								disabled={false}
							/>
						)}
					/>
					{enableAudienceTargeting && (
						<Controller
							control={control}
							name="audience"
							render={({ field: { ref, ...fieldWithoutRef } }) => (
								<MultiSelectInput
									{...fieldWithoutRef}
									label="Audience (optional)"
									placeholder="- Select an audience -"
									values={playtestAudiences}
									maxSelection="4"
								/>
							)}
						/>
					)}
					{!inspectorPassed && (
						<Error>
							This version has not passed step 1 of the <a href={inspectorURL} target="_blank" rel="noopener noreferrer">Inspector</a> SDK Basics module:<br /><i>Has the game initialized the SDK?</i> This is required to start a metrictest.
						</Error>
					)}
					<Buttons>
						<Button submit disabled={!inspectorPassed}>Start Metrictest</Button>
					</Buttons>
				</form>
			)}
		</Modal>
	);
};

registerModal('start-new-metrictest', StartNewMetrictestModal);
