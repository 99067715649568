import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { Helmet } from 'react-helmet';

import LockIcon from 'shared/designTokens/icons/ui/small/LockIcon';
import PencilIcon from 'shared/designTokens/icons/ui/medium/PencilIcon';
import PlusIcon from 'shared/designTokens/icons/ui/small/PlusIcon';

import { createDomainCollection, listDomainCollections, patchDomainCollection } from 'app/src/epics/domains';
import { useSelectDomainCollections } from 'app/src/selectors/domains';
import { useSelectPermissions } from 'app/src/selectors/user';
import { openModal } from 'app/src/actions/client';
import useActionCounter from 'app/src/hooks/useActionCounter';
import checkPermissions from 'app/src/utils/checkPermissions';

import GridContainer from 'app/src/components/ui/GridContainer';
import IconButton from 'app/src/components/ui/IconButton';
import Container from 'app/src/components/ui/Container';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import _ from 'shared/copy';

const ItemName = styled.div`
	font-weight: bold;
	display: flex;

	[fill] {
		fill: ${props => props.theme.grey3};
	}

	${props => props.isPoki && `
	color: ${props.theme.grey3};
	`}
`;

const ItemDomains = styled.div`
	${props => props.isPoki && `
	color: ${props.theme.grey3};
	`}
`;

const AdminDomainsPage = () => {
	const dispatch = useDispatch();

	const listDomainCollectionsStatus = useSelectApiStatus(listDomainCollections.id);
	const domainCollections = useSelectDomainCollections();
	const permissions = useSelectPermissions();

	const actionCounter = useActionCounter(
		createDomainCollection.success.type,
		patchDomainCollection.success.type,
	);

	useEffect(() => {
		dispatch(listDomainCollections.fetch());
	}, [actionCounter]);

	return (
		<Container>
			<Helmet key="AdminDomainsPage">
				<title>Domains - Admin - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				<Card
					title={_`domainCollections`}
					noPadding
					buttons={[
						{
							id: 'add-new-domain-collection',
							type: 'button',
							icon: PlusIcon,
							action: () => dispatch(openModal({ key: 'manage-domain-collection' })),
							disabled: !checkPermissions(permissions, [['can_edit_domain_collections']]),
							children: _`addNew`,
						},
					]}
				>
					<Table
						isLoading={!listDomainCollectionsStatus.done}
						items={domainCollections}
						itemAlign="top"
						columns={[
							{
								title: _`name`,
								content: ({ item }) => (
									<ItemName isPoki={item.name === 'poki'}>
										{item.name === 'poki' && <LockIcon />}
										{item.name}
									</ItemName>
								),
							},
							{
								title: _`domains`,
								content: ({ item }) => (
									<ItemDomains isPoki={item.name === 'poki'}>
										{item.domains.join(', ')}
									</ItemDomains>
								),
							},
							checkPermissions(permissions, [['can_edit_domain_collections']]) && (
								{
									title: _`edit`,
									width: 'max-content',
									content: ({ item }) => (
										<IconButton
											disabled={item.name === 'poki'}
											title={_`edit`}
											icon={PencilIcon}
											onClick={() => dispatch(openModal({ key: 'manage-domain-collection', data: { name: item.name } }))}
										/>
									),
								}),
						]}
					/>
				</Card>
			</GridContainer>
		</Container>
	);
};

export default AdminDomainsPage;
