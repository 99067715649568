import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelectApiStatus, combinedApiStatus } from '@poki/rx-api';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import StarFilledIcon from 'shared/designTokens/icons/ui/small/StarFilledIcon';
import ThumbsUpIcon from 'shared/designTokens/icons/ui/small/ThumbsUpIcon';
import ThumbsDownIcon from 'shared/designTokens/icons/ui/small/ThumbsDownIcon';
import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';
import SearchIcon from 'shared/designTokens/icons/ui/small/SearchIcon';

import { getPlayerFeedbackForGame, getPlayerFeedbackTotalForGame, getPlayerFeedbackCountriesForGame, getPlayerFeedbackQualityTypesForGame, getPlayerFeedbackRatingTypesForGame, getTablesLastUpdatedAt, getPlayerFeedbackVersionsForGame } from 'app/src/epics/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { useSelectPermissions } from 'app/src/selectors/user';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { pushEvent } from 'app/src/utils/tracking';
import { UnauthorizedError } from 'app/src/errors';
import checkPermissions from 'app/src/utils/checkPermissions';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import formatNumber from 'app/src/utils/formatNumber';
import getTimeSince from 'app/src/utils/getTimeSince';
import useURLState from 'app/src/hooks/useURLState';
import parseMessage from 'app/src/utils/parseMessage';

import GamePlayerFeedbackDetails from 'app/src/components/ui/GamePlayerFeedbackDetails';
import GridContainer from 'app/src/components/ui/GridContainer';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';
import Flag from 'app/src/components/ui/Flag';

import { isMobile } from 'shared/vars';
import _, { parseEmoji } from 'shared/copy';

const Rating = styled.div`
	width: 320px;
	margin-left: auto;

	${isMobile && `
	width: 100%;
	`}
`;

const RatingTop = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`;

const RatingTitle = styled.div`
	font-size: 16px;
	line-height: 24px;
	font-weight: bold;
`;

const Stars = styled.div`
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	display: flex;
	align-items: center;

	svg [fill] {
		fill: ${props => props.theme.yellow5};
	}
`;

const RatingBar = styled.div`
	position: relative;
	height: 8px;
	border-radius: 16px;
	overflow: hidden;
	background: ${props => props.theme.rose5};

	::after {
		content: '';
		display: block;
		transform-origin: left;
		transform: scaleX(${props => props.size});
		background: ${props => props.theme.green5};
		width: 100%;
		height: 100%;
	}

	::before {
		content: '';
		display: block;
		width: 3px;
		height: 100%;
		background: ${props => props.theme.pureWhite};
		position: absolute;
		top: 0;
		left: ${props => props.size * 100}%;
	}
`;

const ThumbsContainer = styled.div`
	display: flex;
	font-size: 14px;
	line-height: 20px;
	color: ${props => props.theme.grey3};
	justify-content: space-between;
`;

const Thumbs = styled.div`
	display: flex;
	align-items: center;

	svg {
		margin: 4px;
	}

	[fill] {
		fill: ${props => props.theme.grey3};
	}
`;

const Positive = styled.span`
	color: ${props => props.theme.green1};
`;

const Negative = styled.span`
	color: ${props => props.theme.rose1};
`;

const perPage = 16;

const GamePlayerFeedbackSubPage = props => {
	const { game } = props;

	const permissions = useSelectPermissions();

	if (game?.annotations?.license_fee === 'yes' && !checkPermissions(permissions, [['can_see_all_clickhouse_data']])) {
		throw new UnauthorizedError();
	}

	const dispatch = useDispatch();
	const activeTeam = useSelectActiveTeam();

	const [page, setPage] = useURLState('page', 1);
	const [sort, setSort] = useURLState('sort', { field: 'timestamp', direction: -1 });

	const [defaultFilterDataSet, setDefaultFilterDataSet] = useState(false);
	const [activeFilters, setActiveFilters] = useURLState('filters', { probably_spammy: [0] });

	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_player_feedback');

	useEffect(() => {
		dispatch(getTablesLastUpdatedAt.fetch());
	}, []);

	const { total: totalFeedback } = useSelectDataByEpic(getPlayerFeedbackTotalForGame.id);
	const { rows: playerFeedback, total: filteredTotal, included } = useSelectDataByEpic(getPlayerFeedbackForGame.id);

	const { rows: countries } = useSelectDataByEpic(getPlayerFeedbackCountriesForGame.id);
	const { rows: ratingTypes } = useSelectDataByEpic(getPlayerFeedbackRatingTypesForGame.id);
	const { rows: qualityTypes } = useSelectDataByEpic(getPlayerFeedbackQualityTypesForGame.id);
	const { rows: versionIds } = useSelectDataByEpic(getPlayerFeedbackVersionsForGame.id);

	const attributedVersions = useMemo(() => (
		versionIds.map(({ ...row }) => ({
			...row,
			p4dVersion: game?.versions.find(v => v.id === row.p4d_version_id),
		}))
	), [versionIds, game?.versions]);

	const getFilterDataStatus = combinedApiStatus(
		useSelectApiStatus(getPlayerFeedbackCountriesForGame.id),
		useSelectApiStatus(getPlayerFeedbackRatingTypesForGame.id),
		useSelectApiStatus(getPlayerFeedbackQualityTypesForGame.id),
		useSelectApiStatus(getPlayerFeedbackVersionsForGame.id),
	);

	const getPlayerFeedbackStatus = combinedApiStatus(
		getFilterDataStatus,
		useSelectApiStatus(getPlayerFeedbackForGame.id),
		useSelectApiStatus(getPlayerFeedbackTotalForGame.id),
	);

	const filters = useMemo(() => ([
		{
			title: _`type`,
			field: 'type',
			type: 'multiselect',
			values: ratingTypes.map(({ type, count }) => ({
				value: type,
				desc: type === 'thumbs_up' ? _`positive` : (type === 'thumbs_down' ? _`negative` : _`bugreport`),
				count,
			})) || [],
		},
		{
			title: _`country`,
			field: 'country_code',
			type: 'multiselect',
			values: countries.map(({ country, country_code, count }) => ({
				value: country_code,
				desc: country,
				count,
			})) || [],
		},
		{
			title: _`version`,
			field: 'p4d_version_id',
			type: 'multiselect',
			values: attributedVersions.filter(({ p4d_version_id }) => p4d_version_id !== null).map(({ p4d_version_id, count, p4dVersion }) => ({
				value: p4d_version_id,
				sortValue: p4dVersion?.created_at,
				desc: getVersionLabel(p4dVersion) || p4d_version_id,
				count,
			})) || [],
		},
		{
			title: _`estimatedQuality`,
			field: 'probably_spammy',
			type: 'multiselect',
			values: qualityTypes.filter(({ probably_spammy }) => probably_spammy !== null).map(({ probably_spammy, count }) => ({
				value: probably_spammy,
				desc: probably_spammy ? _`lower` : _`higher`,
				count,
			})) || [],
		},
		{
			title: _`response`,
			field: 'LIKE:message',
			icon: SearchIcon,
			small: true,
			type: 'search',
		},
	]), [ratingTypes, countries, qualityTypes, attributedVersions]);

	useEffect(() => {
		if (getFilterDataStatus.done && !defaultFilterDataSet) {
			if (Object.keys(activeFilters).length === 0) {
				const defaultActiveFilterData = {};

				filters.forEach(({ field, values }) => {
					if (values && values.length && values.some(val => 'uncheckedByDefault' in val)) {
						const filtered = values.filter(val => !val.uncheckedByDefault);

						if (filtered.length > 0) {
							defaultActiveFilterData[field] = filtered.map(val => val.value);
						}
					}
				});

				setActiveFilters(defaultActiveFilterData);
			}
			setDefaultFilterDataSet(true);
		}
	}, [game, getPlayerFeedbackStatus, getFilterDataStatus, defaultFilterDataSet]);

	useEffect(() => {
		if (game && defaultFilterDataSet) {
			dispatch(getPlayerFeedbackForGame.fetch({ gameId: game.id, teamId: game.team.id, page, perPage, sortField: sort.field, sortDirection: sort.direction, filters: activeFilters }));
		}
	}, [game, page, sort, activeFilters, defaultFilterDataSet]);

	useEffect(() => {
		if (game) {
			dispatch(getPlayerFeedbackTotalForGame.fetch({ gameId: game.id, teamId: game.team.id }));
		}
	}, [game]);

	useEffect(() => {
		if (game) {
			dispatch(getPlayerFeedbackCountriesForGame.fetch({ gameId: game.id, teamId: game.team.id, filters: activeFilters }));
			dispatch(getPlayerFeedbackRatingTypesForGame.fetch({ gameId: game.id, teamId: game.team.id, filters: activeFilters }));
			dispatch(getPlayerFeedbackQualityTypesForGame.fetch({ gameId: game.id, teamId: game.team.id, filters: activeFilters }));
			dispatch(getPlayerFeedbackVersionsForGame.fetch({ gameId: game.id, teamId: game.team.id, filters: activeFilters }));
		}
	}, [game, activeFilters]);

	const { rating } = game.content_metadata || {};

	return (
		<>
			<Helmet key={`GamePlayerFeedbackSubPage-${game.id}-${activeTeam.code}`}>
				<title>Player Feedback - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<h2>{_`playerFeedback`}</h2>
			<GridContainer cols={2}>
				<div>
					<p dangerouslySetInnerHTML={{ __html: parseEmoji(_`playerFeedbackDescription`) }} />
				</div>
				{rating && (
					<Rating>
						<RatingTop>
							<RatingTitle>{_`rating`}</RatingTitle>
							<Stars><StarFilledIcon />{rating.rating.toFixed(1)}</Stars>
						</RatingTop>
						<RatingBar size={rating.up_count / (rating.up_count + rating.down_count)} />
						<ThumbsContainer>
							<Thumbs>
								<ThumbsUpIcon />
								{_`xVotes${{ votes: formatNumber(rating.up_count, { shorten: true }) }}`}
							</Thumbs>
							<Thumbs>
								{_`xVotes${{ votes: formatNumber(rating.down_count, { shorten: true }) }}`}
								<ThumbsDownIcon />
							</Thumbs>
						</ThumbsContainer>
					</Rating>
				)}
			</GridContainer>
			<GridContainer cols={1}>
				<Card
					title={_`responses`}
					description={(!getPlayerFeedbackStatus.done && totalFeedback === 0) || typeof totalFeedback === 'undefined' ? _`loading` : _`xPlayersLeftResponses${{ amount: totalFeedback }}`}
					noPadding
					buttons={[
						{
							id: 'player-feedback-last-updated',
							type: 'info',
							disabled: !lastUpdatedAt,
							icon: GraphIcon,
							children: _`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`,
						},
					]}
				>
					<Table
						isLoading={!getPlayerFeedbackStatus.done}
						items={playerFeedback}
						page={page}
						perPage={perPage}
						totalItems={filteredTotal}
						sortField={sort.field}
						sortDirection={sort.direction}
						setSort={setSort}
						setPagination={setPage}
						setActiveFilters={setActiveFilters}
						filters={filters}
						activeFilters={activeFilters}
						columns={[
							{
								title: _`date`,
								content: ({ item }) => moment.utc(item.timestamp).format('D MMMM YYYY'),
								sortField: 'timestamp',
								isDefaultSort: true,
							},
							{
								title: _`type`,
								content: ({ item }) => (
									item.type === 'thumbs_up' ? (
										<Positive dangerouslySetInnerHTML={{ __html: `${parseEmoji('👍')} ${_`positive`}` }} />
									) : (item.type === 'thumbs_down' ? (
										<Negative dangerouslySetInnerHTML={{ __html: `${parseEmoji('👎')} ${_`negative`}` }} />
									) : (
										<span dangerouslySetInnerHTML={{ __html: `${parseEmoji('🐛')} ${_`bugreport`}` }} />
									))
								),
								sortField: 'type',
							},
							{
								title: _`country`,
								content: ({ item }) => (
									<><Flag countryCode={item.country_code} />{item.country}</>
								),
								sortField: 'country',
							},
							{
								title: _`response`,
								width: '1fr',
								content: ({ item }) => (
									<span dangerouslySetInnerHTML={{ __html: parseMessage(item.message, false) }} />
								),
							},
						]}
						onToggleDetailsActive={(toggle, item) => {
							if (toggle) {
								pushEvent('playerFeedback', 'expand', {
									rating: item.type,
									countryCode: item.country_code,
								});
							}
						}}
						details={({ item }) => <GamePlayerFeedbackDetails item={item} included={included} />}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default GamePlayerFeedbackSubPage;
