import * as Sentry from '@sentry/browser';

import { SERVICE_ENV } from 'app/vars';

let sentryInitialized = false;

const initSentry = () => {
	if (sentryInitialized) return;

	if (process.env.NODE_ENV === 'production') {
		Sentry.init({
			environment: SERVICE_ENV,
			dsn: 'https://5e77ea8e48154018a283a3a9cb1d15ab@sentry.io/1808427',
		});
	}

	sentryInitialized = true;
};

export { initSentry };
