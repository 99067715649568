import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSelectApiStatus } from '@poki/rx-api';
import { tap, ignoreElements } from 'rxjs';

import Card from 'app/src/components/ui/Card';
import { getGameAllowList, patchGameAllowList } from 'app/src/epics/game';
import { openModal } from 'app/src/actions/client';
import { useSelectPermissions } from 'app/src/selectors/user';
import MultiToggleInput from 'app/src/components/input/MultiToggleInput';
import CheckBoxInput from 'app/src/components/input/CheckBoxInput';
import Table from 'app/src/components/ui/Table';
import Button, { ButtonText, ButtonTextContainer } from 'app/src/components/ui/Button';
import { useSelectDomainAllowListByGameId, useSelectDomainCollections } from 'app/src/selectors/domains';
import TriggerableMultiSelectInput from 'app/src/components/input/TriggerableMultiSelectInput';
import { listDomainCollections } from 'app/src/epics/domains';
import useTrackUnsavedChanges from 'app/src/hooks/useTrackUnsavedChanges';
import checkPermissions from 'app/src/utils/checkPermissions';
import LockIcon from 'shared/designTokens/icons/ui/tiny/LockIcon';
import _ from 'shared/copy';

const TypeText = styled.div`
	max-width: 420px;
	text-align: center;
	margin-bottom: 32px;
`;

const Center = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const DomainCollection = styled.div`
	display: flex;
	flex-direction: column;
`;

const DomainCollectionName = styled.div`
	display: flex;
	align-items: center;
	font-weight: bold;

	[fill] {
		fill: ${props => props.theme.grey1};
	}
`;

const DomainsContainer = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: ${props => props.theme.grey3};
`;

const ShowMore = styled.div`
	font-weight: bold;
	color: ${props => props.theme.pokiBlue};
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const StyledButtonTextContainer = styled(ButtonTextContainer)`
	margin: 0;
`;

const ActionsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 24px 0 0;
`;

const Domains = ({ domains }) => {
	const first10 = domains.slice(0, 10);
	const rest = domains.slice(10);

	const [open, setOpen] = useState(false);

	return (
		<DomainsContainer>
			{first10.join(', ')}
			{rest.length > 0 && (
				(!open ? (
					<ShowMore onClick={() => setOpen(true)}>{_`showXMore${{ amount: rest.length }}`}</ShowMore>
				) : (
					<>
						, {rest.join(', ')}
						<ShowMore onClick={() => setOpen(false)}>{_`showLess`}</ShowMore>
					</>
				))
			)}
		</DomainsContainer>
	);
};

const GameDomainAllowListModule = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const formRef = useRef();

	const [ready, setReady] = useState(false);
	const [type, setType] = useState('poki');
	const [globalAllowMobile, setGlobalAllowMobile] = useState(false);
	const [activeDomainCollections, setActiveDomainCollections] = useState([]);
	const [allowMobile, setAllowMobile] = useState({ poki: true });
	const [hideFooter, setHideFooter] = useState({ poki: true });

	const domainCollections = useSelectDomainCollections();
	const allowList = useSelectDomainAllowListByGameId(game.id);
	const listDomainCollectionsStatus = useSelectApiStatus(listDomainCollections.id);
	const patchGameAllowListStatus = useSelectApiStatus(patchGameAllowList.id);

	const { unsavedChanges, resetFormData } = useTrackUnsavedChanges(formRef);
	const permissions = useSelectPermissions();

	const canRemoveGames = checkPermissions(permissions, [['can_delete_all_games', 'can_delete_owned_games']]);

	const attributedActiveDomainCollections = useMemo(() => (
		domainCollections
			.filter(dc => activeDomainCollections.find(adc => adc === dc.name))
			.map(dc => ({
				...dc,
				allowMobile: !!allowMobile[dc.name],
				hideFooter: !!hideFooter[dc.name],
			}))
	), [domainCollections, activeDomainCollections, allowMobile, hideFooter]);

	const handleSubmit = evt => {
		evt.preventDefault();

		// Only having poki in a custom set is the same as being of type 'poki'
		// and backend doesn't like us giving a type 'custom' with only poki in it
		let finalType = type;
		if (attributedActiveDomainCollections.length === 1 && attributedActiveDomainCollections[0].name === 'poki') {
			finalType = 'poki';
		}

		dispatch(
			patchGameAllowList.fetch(
				{
					gameId: game.id,
					type: finalType,
					allowMobile: globalAllowMobile,
					domainCollections: type === 'custom' ? attributedActiveDomainCollections : [],
				},
				({ success$ }) => success$.pipe(
					tap(() => resetFormData()),
					ignoreElements(),
				),
			),
		);
	};

	const openRemoveGameModal = () => dispatch(openModal(
		{
			key: 'confirm-remove-game',
			data: { gameName: game.title, gameId: game.id, teamCode: game.team.code },
		},
	));

	useEffect(() => {
		setReady(false);
		dispatch(listDomainCollections.fetch());

		if (game) {
			dispatch(getGameAllowList.fetch({ gameId: game.id }));
		}
	}, [game?.id]);

	useEffect(() => {
		if (allowList && domainCollections.length > 0) {
			setType(allowList.type);
			setGlobalAllowMobile(allowList.allow_mobile);

			setActiveDomainCollections([
				domainCollections.find(dc => dc.name === 'poki'),
				...(allowList.domain_collections || []),
			].map(dc => dc.name));

			const tmpAllowMobile = { poki: true };
			(allowList.domain_collections || []).forEach(dc => {
				tmpAllowMobile[dc.name] = dc.allow_mobile;
			});

			const tmpHideFooter = { poki: true };
			(allowList.domain_collections || []).forEach(dc => {
				tmpHideFooter[dc.name] = dc.hide_footer;
			});

			setAllowMobile(tmpAllowMobile);
			setHideFooter(tmpHideFooter);

			if (!ready) {
				setReady(true);
			}
		}
	}, [domainCollections, allowList]);

	useEffect(() => {
		if (ready) {
			resetFormData();
		}
	}, [ready]);

	const createSetAllowMobile = name => value => (
		setAllowMobile(curr => ({
			...curr,
			[name]: value,
		}))
	);

	const createSetHideFooter = name => value => (
		setHideFooter(curr => ({
			...curr,
			[name]: value,
		}))
	);

	return (
		<Card
			title={_`domainAllowList`}
			description={_`domainAllowListDescription`}
		>
			{!ready ? (
				_`loading`
			) : (
				<form ref={formRef} onSubmit={handleSubmit}>
					<Center>
						<MultiToggleInput
							name="domainAllowList_type"
							values={[
								{
									value: 'poki',
									desc: _`onlyPlayableOnPoki`,
								},
								{
									value: 'everywhere',
									desc: _`playableEverywhere`,
								},
								{
									value: 'custom',
									desc: _`custom`,
								},
							]}
							value={type}
							valueSetter={setType}
						/>
						{type === 'poki' ? (
							<TypeText>{_`onlyPlayableOnPokiDescription`}</TypeText>
						) : type === 'everywhere' ? (
							<>
								<TypeText>{_`playableEverywhereDescription`}</TypeText>
								<CheckBoxInput
									text={_`allowOnMobileEverywhere`}
									name="domainAllowList_allowMobile"
									value={globalAllowMobile}
									valueSetter={setGlobalAllowMobile}
								/>
							</>
						) : (
							<>
								<TypeText>{_`customAllowListDescription`}</TypeText>
								<Card
									title={_`customDomainAllowList`}
									noPadding
									buttons={[
										{
											id: `add-domain-collection-${game.id}`,
											type: 'custom',
											children: (
												<TriggerableMultiSelectInput
													name={`add-domain-collection-${game.id}`}
													title={_`domainCollections`}
													align="right"
													trigger={onToggle => (
														<Button
															onClick={onToggle}
															disabled={!listDomainCollectionsStatus.done}
														>
															{_`selectCollections`}
														</Button>
													)}
													values={domainCollections.map(dc => ({
														value: dc.name,
														desc: dc.name,
														locked: dc.name === 'poki',
													}))}
													value={activeDomainCollections}
													valueSetter={setActiveDomainCollections}
												/>
											),
										},
									]}
								>
									<Table
										items={attributedActiveDomainCollections}
										columns={[
											{
												title: _`domainCollection`,
												content: ({ item }) => (
													<DomainCollection>
														<DomainCollectionName>{item.name === 'poki' && <LockIcon />}{item.name}</DomainCollectionName>
														<Domains domains={item.domains} />
													</DomainCollection>
												),
											},
											{
												title: _`allowOnMobile`,
												width: 'max-content',
												content: ({ item }) => (
													<CheckBoxInput
														disabled={item.name === 'poki'}
														value={allowMobile[item.name]}
														valueSetter={createSetAllowMobile(item.name)}
														name={`domainAllowList_allowMobile_${item.name}`}
													/>
												),
											},
											{
												title: _`hideGameplayerFooter`,
												width: 'max-content',
												content: ({ item }) => (
													<CheckBoxInput
														disabled={item.name === 'poki'}
														value={hideFooter[item.name]}
														valueSetter={createSetHideFooter(item.name)}
														name={`domainAllowList_hideFooter_${item.name}`}
													/>
												),
											},
										]}
									/>
								</Card>
							</>
						)}
					</Center>
					<ActionsContainer>
						<StyledButtonTextContainer>
							<Button submit disabled={!unsavedChanges} primary>{_`save`}</Button>
							<ButtonText warning={unsavedChanges}>
								{patchGameAllowListStatus.pending ? _`saving` : unsavedChanges ? _`unsavedChanges` : ''}
							</ButtonText>
						</StyledButtonTextContainer>
						{canRemoveGames && (
							<Button negative primary onClick={openRemoveGameModal} disabled={game?.content_metadata?.release_status && game?.content_metadata?.release_status !== 'not-released'}>{_`removeGame`}</Button>
						)}
					</ActionsContainer>
				</form>
			)}
		</Card>
	);
};

export default GameDomainAllowListModule;
