import React, { useEffect, useState, useMemo, useRef } from 'react';
import { ignoreElements, merge, of, switchMap, tap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { deletePlaytestRequest, getPlaytestRecordingsByGameId } from 'app/src/epics/playtests';
import { setGameChecklistStatus, setHasPlaytestNotifications } from 'app/src/actions/session';
import { useSelectPlaytestRecordingsByGameId } from 'app/src/selectors/playtests';
import { useSelectHasPlaytestNotifications } from 'app/src/selectors/session';
import { getGameById, listGamesByTeamId } from 'app/src/epics/game';
import { useSelectGameVersionsForPlaytests } from 'app/src/selectors/game';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { openModal, uncaughtServerError } from 'app/src/actions/client';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import playtestFAQItems from 'shared/utils/playtestFAQItems';
import formatTime from 'app/src/utils/formatTime';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';

import BellEmptyIcon from 'shared/designTokens/icons/ui/small/BellEmptyIcon';
import BellFilledIcon from 'shared/designTokens/icons/ui/small/BellFilledIcon';
import DesktopScreenIcon from 'shared/designTokens/icons/ui/small/DesktopScreenIcon';
import MobilePhoneIcon from 'shared/designTokens/icons/ui/small/MobilePhoneIcon';
import StopIcon from 'shared/designTokens/icons/ui/small/StopIcon';
import PencilIcon from 'shared/designTokens/icons/ui/tiny/PencilIcon';
import DocumentIcon from 'shared/designTokens/icons/ui/small/DocumentIcon';
import PlusIcon from 'shared/designTokens/icons/ui/small/PlusIcon';

import Details from 'app/src/components/ui/Details';
import Button from 'app/src/components/ui/Button';
import Table from 'app/src/components/ui/Table';
import Tooltip from 'shared/components/Tooltip';
import OldTooltip from 'app/src/components/ui/Tooltip';
import Card from 'app/src/components/ui/Card';
import Flag from 'app/src/components/ui/Flag';

import { dayMonthTimeFormat, _small, isMobile } from 'shared/vars';
import playtestAudiences from 'shared/utils/playtestAudiences';
import _ from 'shared/copy';

const StyledFlag = styled(Flag)`
	font-size: 17px;
	left: 3px;
	top: 2px;
`;

const StyledDescriptionContainer = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 40px;
	align-self: stretch;
	flex-flow: wrap-reverse;

	${_small} {
		flex-flow: nowrap;
	}
`;

const StyledDescriptionWrapper = styled.div`
	display: flex;
	padding-bottom: 8px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;
	min-width: 100%;

	${_small} {
		min-width: auto;
	}
`;

const StyledDescription = styled.span`
	font: 400 18px/30px "Proxima Nova",Arial;
`;

const StyledList = styled.ul`
	font: 400 16px/24px "Proxima Nova";
	margin: 0;
	padding: 0 16px;
`;

const StyledImageFrame = styled.a`
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex: 1 0 0;
	border-radius: 8px;
	background: ${props => props.theme.grey7};
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;

const StyledImg = styled.img`
	width: 100%;
	height: 100%;
`;

const RecordedPlaytestPlayed = styled.div`
	font-weight: bold;
	color: ${props => props.theme.grey1};

	${props => props.$isPlayed && `
		font-weight: normal;
	`}
`;

const StyledNotification = styled.span`
	display: inline-block;
	margin-right: 8px;
	width: 10px;
	height: 10px;
	border-radius: 10px;

	background-color: ${props => props.theme.rose1};
	color: ${props => props.theme.static.pureWhite};
`;

const StyledCard = styled(Card)`
	background: none;
	box-shadow: none;
`;

const NotificationIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 36px;
	height: 36px;
	border-radius: 50%;
	border: 2px solid ${props => props.theme.pokiBlue};

	${props => props.$isNotificationEnabled && `
		background-color: ${props.theme.pokiBlue};

		svg {
			[fill] {
				fill: ${props.theme.pureWhite};
			}
		}
	`}

	${props => props.$isClickDisabled && `
		opacity: 0.5;
		pointer-events: none;
	`}
`;

const StyledTooltip = styled(Tooltip)`
	display: flex;
	align-items: center;
`;

const DeviceInformationContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const PlaytestRequestDetailsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	justify-content: space-between;
	padding: 16px;

	${_small} {
		align-items: flex-start;
	}
`;

const StyledDetails = styled(Details)`
	margin-bottom: 0;

	summary {
		color: ${props => props.theme.pokiBlue};
	}
`;

const RecordedPlaytestsDetailstTitle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	${_small} {
		flex-direction: row;
	}
`;

const DetailsList = styled.ul`
	margin: 0;
	padding: 8px 0;
	list-style: none;
`;

const DetailsListItem = styled.li`
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 14px;
	color: ${props => props.theme.denimBlue};
`;

const NoPlaytestNotes = styled.span`
	font-style: italic;
`;

const PlaytestNotes = styled.div`
	white-space: pre-wrap;
	max-height: 250px;
	overflow: auto;
`;

const PlaytestNotesIcon = styled(DocumentIcon)`
	position: relative;
	left: 10px;
	margin-left: -18px;

	${props => (!props.$notesAvailable ? `
	[fill] {
		fill: ${props.theme.grey5};
	}
	` : '')}
`;

const WHAT_YOU_GET_ID = 'what-you-get-with-poki-playtest';
const FETCH_RECORDINGS_INTERVAL = 30;

const getMappedCategories = ({ categories }) => {
	if (!categories) {
		return '';
	}

	const splitCategories = categories.split(',');

	const reducedCategories = splitCategories.reduce((acc, c) => {
		const { desc } = playtestAudiences.find(({ value }) => value === c);

		return acc.concat(desc);
	}, []);

	return reducedCategories.join(', ');
};

const RecordedPlaytests = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const activeTeam = useSelectActiveTeam();
	const versions = useSelectGameVersionsForPlaytests(game.id);
	const isNotificationsEnabled = useSelectHasPlaytestNotifications();
	const permissions = useSelectPermissions();

	const previousPlaytestRecordings = useRef(null);

	// We need to keep track of whether we've requested playtest recordings or not, because else
	// the button will only disable after the first list fetch has completed
	const [page, setPage] = useState(1);
	const [requestedPlaytestRecordings, setRequestedPlaytestRecordings] = useState(false);
	const [notificationPermission, setNotificationPermission] = useState(window.Notification?.permission);

	const getPlaytestRecordingsByGameIdStatus = useSelectApiStatus(getPlaytestRecordingsByGameId.id);
	const { data: playtestRecordings } = useSelectPlaytestRecordingsByGameId(game.id);

	const tableLoadingDone = (playtestRecordings && playtestRecordings.length > 0) || getPlaytestRecordingsByGameIdStatus.done;
	const isPermissionGranted = notificationPermission === 'granted';
	const isClickDisabled = !isNotificationsEnabled && notificationPermission === 'denied';

	// Filter out the playtest request that has recordings
	const playtestRequest = useMemo(() => {
		if (!game.playtest_requests) return null;

		return game.playtest_requests.filter(r => r.recordings !== 0 || r.pending !== 0)[0];
	}, [game.playtest_requests]);

	useEffect(() => {
		if (notificationPermission === 'default' || notificationPermission === 'denied') {
			dispatch(setHasPlaytestNotifications({ isEnabled: false }));
		}
	}, [notificationPermission]);

	// Fetch a list of recordings when the page opens.
	useEffect(() => {
		dispatch(getPlaytestRecordingsByGameId.fetch({ gameId: game.id }));

		const i = setInterval(() => {
			dispatch(getPlaytestRecordingsByGameId.fetch({ gameId: game.id }));
			dispatch(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
			dispatch(getGameById.fetch(game.id));
		}, FETCH_RECORDINGS_INTERVAL * 1000);

		return () => {
			clearInterval(i);
		};
	}, []);

	const onSuccessCallback = () => {
		if (!game.approved) {
			dispatch(setGameChecklistStatus({ gameId: game.id, level: 1, step: 3, status: 'pending' }));
		}

		dispatch(getGameById.fetch(game.id));
		setRequestedPlaytestRecordings(true);
	};

	const handleStartNewPlaytest = () => {
		const { id, content_metadata: { release_status } = {} } = game;

		dispatch(openModal({
			key: 'start-new-playtest',
			data: { gameId: id, releaseStatus: release_status, onSuccessCallback },
		}));
	};

	const handleStopPlaytestRequest = () => {
		const { id } = playtestRequest;

		dispatch(deletePlaytestRequest.fetch({ gameId: game.id, playtestRequestId: id }, ({ success$, error$ }) => merge(
			success$.pipe(
				tap(() => {
					dispatch(getGameById.fetch(game.id));
					setRequestedPlaytestRecordings(false);
				}),
				ignoreElements(),
			),
			error$.pipe(
				switchMap(action => of(uncaughtServerError({ action }))),
			),
		)));
	};

	const requestNotificationPermission = enable => {
		if (enable && notificationPermission === 'default') {
			window.Notification.requestPermission().then(permission => {
				setNotificationPermission(permission);
				dispatch(setHasPlaytestNotifications({ isEnabled: permission === 'granted' }));
			});
		} else {
			dispatch(setHasPlaytestNotifications({ isEnabled: !isNotificationsEnabled }));
		}
	};

	useEffect(() => {
		setRequestedPlaytestRecordings(playtestRequest);
	}, [playtestRequest]);

	useEffect(() => {
		const haveNewRecordings = previousPlaytestRecordings.current && playtestRecordings && previousPlaytestRecordings.current.length < playtestRecordings.length;

		if (isNotificationsEnabled && isPermissionGranted && haveNewRecordings) {
			const notification = new window.Notification(_`playtestRecordingNotification${{ gameName: game.name }}`);

			notification.onclick = () => {
				window.focus();
				notification.close();
			};
		}

		previousPlaytestRecordings.current = playtestRecordings;
	}, [playtestRecordings, isNotificationsEnabled, isPermissionGranted]);

	const canRequestRecordings = checkPermissions(permissions, [['can_edit_all_playtests', 'can_edit_owned_playtests']]);

	return (
		<>
			<Card
				title="What you get with Poki Playtesting"
				id={WHAT_YOU_GET_ID}
				closeable
			>
				<StyledDescriptionContainer>
					<StyledDescriptionWrapper>
						<StyledDescription>
							Watch recordings of real players testing your game. Quick and easy, from behind your desk.
						</StyledDescription>
						<StyledList>
							<li>Test a game concept early in development.</li>
							<li>Improve your game&apos;s onboarding.</li>
							<li>See where players drop off.</li>
							<li>Try out new game levels and mechanics.</li>
							<li>See the excitement your game brings to players!</li>
						</StyledList>
					</StyledDescriptionWrapper>
					<StyledImageFrame href="/images/playtest.png" target="_blank">
						<StyledImg src="/images/playtest.png" />
					</StyledImageFrame>
				</StyledDescriptionContainer>
			</Card>
			<Card
				title="Playtest Recordings"
				noPadding
				elevated
				buttons={[
					{
						id: 'enable-notifications',
						type: 'custom',
						children: (
							<Tooltip
								arrow
								placement="top-end"
								maxWidth="250px"
								content={(
									<>
										<strong>Enable desktop notifications</strong>
										{isClickDisabled ? (
											<p>Notifications are disabled. Make sure to enable them in your browser settings.</p>
										) : (
											<p>For when a new recording is available. Keep this browser tab open to receive the notifications.</p>
										)}
									</>
								)}
							>
								<NotificationIcon
									onClick={requestNotificationPermission}
									$isNotificationEnabled={isNotificationsEnabled}
									$isClickDisabled={isClickDisabled}
								>
									{notificationPermission === 'denied' ? <BellEmptyIcon /> : isNotificationsEnabled ? <BellFilledIcon /> : <BellEmptyIcon />}
								</NotificationIcon>
							</Tooltip>
						),
					},
					...((canRequestRecordings && [
						{
							id: 'start-new-playtest',
							type: 'button',
							icon: PlusIcon,
							action: handleStartNewPlaytest,
							disabled: versions.length === 0 || playtestRequest,
							children: 'Start New Playtest',
						},
					]) || []),
				]}
			>
				{requestedPlaytestRecordings && playtestRequest && (
					<PlaytestRequestDetailsContainer>
						<StyledDetails
							title={(
								<RecordedPlaytestsDetailstTitle>
									<span>`Recordings in Progress: </span>
									<span>{playtestRequest.pending} / {playtestRequest.recordings + playtestRequest.pending}</span>
								</RecordedPlaytestsDetailstTitle>
							)}
							content={(
								<DetailsList>
									{playtestRequest.categories && playtestRequest.categories !== '' && (
										<DetailsListItem>
											<strong>Audience:</strong>
											<span>{getMappedCategories({ categories: playtestRequest.categories })}</span>
										</DetailsListItem>
									)}
									<DetailsListItem>
										<strong>Device:</strong>
										<span>{playtestRequest.device_category}</span>
									</DetailsListItem>
									{playtestRequest.device_category !== 'desktop' && (
										<DetailsListItem>
											<strong>Orientation:</strong>
											<span>{playtestRequest.orientation}</span>
										</DetailsListItem>
									)}
								</DetailsList>
							)}
						/>
						<Button secondary onClick={handleStopPlaytestRequest} icon={isMobile ? null : StopIcon}>Stop</Button>
					</PlaytestRequestDetailsContainer>
				)}
				<Table
					title={_`recordings`}
					isLoading={!tableLoadingDone}
					items={playtestRecordings || []}
					setPagination={setPage}
					page={page}
					perPage={20}
					totalItems={(playtestRecordings || []).length}
					autoPaging
					columns={[
						{
							title: _`when`,
							content: ({ item }) => (
								<RecordedPlaytestPlayed $isPlayed={item.watched}>
									{!item.watched && <StyledNotification />}
									{moment(item.created_at * 1000).format(dayMonthTimeFormat)}
								</RecordedPlaytestPlayed>
							),
						},
						{
							title: '',
							mobileTitle: _`playtestNotes`,
							width: 'min-content',
							content: ({ item }) => (
								<OldTooltip
									title={_`playtestNotes`}
									body={item.notes ? <PlaytestNotes>{item.notes}</PlaytestNotes> : <NoPlaytestNotes>{_`noPlaytestNotes`}</NoPlaytestNotes>}
									icon={(
										<PencilIcon
											onClick={() => dispatch(openModal({ key: 'edit-playtest-notes', data: { playtest: item } }))}
										/>
									)}
								>
									<PlaytestNotesIcon $notesAvailable={!!item.notes} />
								</OldTooltip>
							),
							mobileContent: ({ item }) => (
								<Button
									secondary
									onClick={() => dispatch(openModal({ key: 'edit-playtest-notes', data: { playtest: item } }))}
								>
									{_`editPlaytestNotes`}
								</Button>
							),
						},
						{
							title: _`version`,
							content: ({ item }) => {
								const version = game.versions.find(v => v.id === item.version_id);

								return (
									version ? (
										getVersionLabel(version)
									) : (
										'Unknown'
									)
								);
							},
						},
						{
							title: _`country`,
							content: ({ item }) => (<StyledFlag countryCode={item.country_id} />),
						},
						{
							title: _`duration`,
							content: ({ item }) => formatTime(item.duration, false),
						},
						{
							title: 'Plays',
							content: ({ item }) => item.gameplay_starts,
						},
						{
							title: _`device`,
							content: ({ item }) => {
								const getDeviceIcon = device => {
									switch (device) {
										case 'desktop':
											return <DesktopScreenIcon />;
										case 'mobile':
										case 'tablet':
											return <MobilePhoneIcon />;
										default:
											return null;
									}
								};

								return (
									<StyledTooltip
										arrow
										placement="top"
										content={(
											<DeviceInformationContent>
												<strong>Device information</strong>
												<div>
													{_`device`}: {item.device_category}<br />
													{_`browser`}: {item.browser}<br />
													{_`operatingSystem`}: {item.os}<br />
												</div>
											</DeviceInformationContent>
										)}
										text={`${item.browser}`}
									>
										{getDeviceIcon(item.device_category)}
									</StyledTooltip>
								);
							},
						},
						{
							title: _`play`,
							content: ({ item }) => <Button to={`/${game.team.code}/games/${game.id}/playtests/${item.id}`}>{_`play`}</Button>,
						},
					]}
				/>
			</Card>
			<StyledCard title="F.A.Q">
				{playtestFAQItems.map(({ isInitiallyOpen, question, answer }) => (
					<Details key={question} title={question} content={answer} isInitiallyOpen={isInitiallyOpen} />
				))}
			</StyledCard>
		</>
	);
};

export default RecordedPlaytests;
