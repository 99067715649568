import React, { useEffect, useMemo } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import moment from 'moment';

import { getTablesLastUpdatedAt, getGameplayCountriesForGame, getRevenueCountriesForGame } from 'app/src/epics/data';
import { useSelectActiveTeam, useSelectNewRevenueSharesByTeamId, useSelectTeamCurrency } from 'app/src/selectors/team';
import { useSelectPermissions, useSelectUser } from 'app/src/selectors/user';
import { getTeamNewRevenueShares } from 'app/src/epics/team';
import { useSelectDataByEpic } from 'app/src/selectors/data';
import { isReleasedForDeveloper } from 'app/src/utils/game';
import { getCountryByCode } from 'app/src/utils/countries';
import { UnauthorizedError } from 'app/src/errors';
import { pushEvent } from 'app/src/utils/tracking';
import { getGameById } from 'app/src/epics/game';
import useURLState from 'app/src/hooks/useURLState';
import checkPermissions from 'app/src/utils/checkPermissions';

import Label, { LabelTypes } from 'app/src/components/ui/Label';
import GridContainer from 'app/src/components/ui/GridContainer';
import MessageBox from 'app/src/components/ui/MessageBox';
import DateRangeInput from 'app/src/components/input/DateRangeInput';
import FilterMultiSelectInput from 'app/src/components/input/FilterMultiSelectInput';
import EmptyMessage from 'app/src/components/ui/EmptyMessage';
import GameGameplaysChartModule from 'app/src/components/modules/GameGameplaysChartModule';
import GameDeveloperEarningsChartModule from 'app/src/components/modules/GameDeveloperEarningsChartModule';
import GameUsersChartModule from 'app/src/components/modules/GameUsersChartModule';
import GameEngagementChartModule from 'app/src/components/modules/GameEngagementChartModule';
import GameMonetizationChartModule from 'app/src/components/modules/GameMonetizationChartModule';
import GameOverviewChecklistModule from 'app/src/components/modules/GameOverviewChecklistModule';
import GameGameplaysPerReferrerChartModule from 'app/src/components/modules/GameGameplaysPerReferrerChartModule';
import GameDeveloperEarningsPerReferrerChartModule from 'app/src/components/modules/GameDeveloperEarningsPerReferrerChartModule';

import { dayMonthYearFormat, earliestMomentInP4D, isMobile, smallPadding } from 'shared/vars';
import _ from 'shared/copy';

const filtersFlex = css`
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media (min-width: 550px) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const Filters = styled.div`
	${filtersFlex}
`;

const FiltersLeft = styled.div`
	${filtersFlex}
`;

const StyledDateRangeInput = styled(DateRangeInput)`
	@media (max-width: 550px) {
		width: 100%;
	}
`;

const StyledGridContainer = styled(GridContainer)`
	${isMobile && `
		padding: 12px ${smallPadding}px;
	`}
`;

const TrafficSourceTitleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 20px 0 4px;
`;

const TrafficSourcesTitle = styled.h2`
	margin: 0;
`;

const TrafficSourcesDesc = styled.p`
	margin: 0 0 20px;
	color: ${props => props.theme.grey3};
`;

const ChartsAndGraphs = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const user = useSelectUser();

	const [deviceCategories, setDeviceCategories] = useURLState('deviceCategories');
	const [countries, setCountries] = useURLState('countries');
	const [contexts, setContexts] = useURLState('contexts');

	const [endDate, setEndDate] = useURLState(
		'endDate',
		(() => {
			const date = moment().tz('Europe/Amsterdam');
			date.subtract(1, 'day');
			date.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
			return date;
		})(),
	);

	const [startDate, setStartDate] = useURLState(
		'startDate',
		(() => {
			const date = moment().tz('Europe/Amsterdam');
			date.subtract(30, 'days');
			date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			return date;
		})(),
	);

	const teamCurrency = useSelectTeamCurrency(game?.team);
	const revenueShares = useSelectNewRevenueSharesByTeamId(game?.team_id);

	const gameRevenueShares = useMemo(() => {
		if (!revenueShares) return null;

		return revenueShares.find(share => share.game_id === game.id);
	}, [revenueShares, game?.id]);

	// API dispatches
	useEffect(() => {
		if (!game) return;

		dispatch(getTablesLastUpdatedAt.fetch());

		dispatch(getTeamNewRevenueShares.fetch({ teamId: game.team_id }));

		// We retrieve countries from both gameplay and revenue data to ensure we have a full list, also for games without revenue
		dispatch(getRevenueCountriesForGame.fetch({ gameId: game.id, teamId: game.team_id, currency: teamCurrency }));
		dispatch(getGameplayCountriesForGame.fetch({ gameId: game.id, teamId: game.team_id }));
	}, [game?.id, startDate, endDate]);

	const { rows: revenueCountriesForGame } = useSelectDataByEpic(getRevenueCountriesForGame.id);
	const { rows: gameplayCountriesForGame } = useSelectDataByEpic(getGameplayCountriesForGame.id);

	const countryFilterValues = useMemo(() => {
		const result = {};

		// Add revenue and gameplay result to result, unique by country id as key
		[...gameplayCountriesForGame, ...revenueCountriesForGame].forEach(country => {
			result[country.country_id] = {
				...(result[country.country_id] || {}),
				...country,
			};
		});

		// Sort by revenue if there is revenue data, otherwise sort by gameplays
		const sortKey = revenueCountriesForGame.length > 0 ? 'developer_earnings' : 'gameplays';

		return Object.values(result).map(c => ({
			value: c.country_id,
			desc: getCountryByCode(c.country_id)?.name,
			sortValue: c[sortKey] || 0,
		})).filter(c => c.value && c.desc);
	}, [game?.id, revenueCountriesForGame, gameplayCountriesForGame]);

	const showEarningsPerReferrer = gameRevenueShares && gameRevenueShares.ingame_organic_landing !== gameRevenueShares.ingame_poki_promotion;

	return (
		<>
			<Filters>
				<FiltersLeft>
					<FilterMultiSelectInput
						name="filter-device-category"
						value={deviceCategories}
						values={[
							{
								value: 'mobile',
								desc: _`mobile`,
								sortValue: 2,
							},
							{
								value: 'tablet',
								desc: _`tablet`,
								sortValue: 1,
							},
							{
								value: 'desktop',
								desc: _`desktop`,
								sortValue: 0,
							},
						]}
						title={_`device`}
						valueSetter={setDeviceCategories}
						light
						cantSelectNone
					/>
					<FilterMultiSelectInput
						name="filter-country"
						value={countries}
						values={countryFilterValues}
						title={_`country`}
						valueSetter={setCountries}
						light
						cantSelectNone
					/>
					{(game.cached_has_external_gameplays || (user.role === 'admin' && game.external_prefix)) && (
						<FilterMultiSelectInput
							name="filter-context"
							value={contexts}
							values={[
								{
									value: 'playground',
									desc: _`poki`,
									sortValue: 1,
								},
								{
									value: 'external',
									desc: _`other`,
									sortValue: 0,
								},
							]}
							title={_`channel`}
							valueSetter={setContexts}
							light
							cantSelectNone
						/>
					)}
				</FiltersLeft>
				<div>
					<StyledDateRangeInput
						allTimeStartDate={moment.unix(game.created_at)}
						minDate={earliestMomentInP4D}
						maxDate={moment.utc().subtract(1, 'day')}
						value={[startDate.format(dayMonthYearFormat), endDate.format(dayMonthYearFormat)]}
						valueSetter={([_startDate, _endDate]) => {
							setStartDate(_startDate);
							setEndDate(_endDate);
						}}
						format={dayMonthYearFormat}
						prefix={`${_`dateRange`}:`}
						light
						onChange={range => {
							pushEvent('gameOverview', 'changeDateRange', {
								days: moment(range[1]).diff(moment(range[0]), 'days') + 1,
							});
						}}
					/>
				</div>
			</Filters>
			<StyledGridContainer cols={2} responsive>
				<GameGameplaysChartModule
					game={game}
					startDate={startDate}
					endDate={endDate}
					deviceCategories={deviceCategories}
					countries={countries}
					contexts={contexts}
				/>
				<GameDeveloperEarningsChartModule
					game={game}
					startDate={startDate}
					endDate={endDate}
					deviceCategories={deviceCategories}
					countries={countries}
					contexts={contexts}
				/>
			</StyledGridContainer>
			<StyledGridContainer cols={3} responsive>
				<div>
					<GameUsersChartModule
						game={game}
						startDate={startDate}
						endDate={endDate}
						deviceCategories={deviceCategories}
						countries={countries}
						contexts={contexts}
					/>
				</div>
				<div>
					<GameEngagementChartModule
						game={game}
						startDate={startDate}
						endDate={endDate}
						deviceCategories={deviceCategories}
						countries={countries}
						contexts={contexts}
					/>
				</div>
				<div>
					<GameMonetizationChartModule
						game={game}
						startDate={startDate}
						endDate={endDate}
						deviceCategories={deviceCategories}
						countries={countries}
						contexts={contexts}
					/>
				</div>
			</StyledGridContainer>
			{/*
			we cannot load this until gameRevenueShares are here because otherwise
			we don't know if we can show earnings per referrer
			*/}
			{gameRevenueShares !== null && (
				<>
					<TrafficSourceTitleContainer>
						<TrafficSourcesTitle>{ _`trafficSources` }</TrafficSourcesTitle>
						<Label type={LabelTypes.NEW} />
					</TrafficSourceTitleContainer>
					<TrafficSourcesDesc dangerouslySetInnerHTML={{ __html: _`trafficSourcesDesc` }} />
					<StyledGridContainer cols={showEarningsPerReferrer || !isMobile ? 2 : 1} responsive>
						<div>
							<GameGameplaysPerReferrerChartModule
								game={game}
								startDate={startDate}
								endDate={endDate}
								deviceCategories={deviceCategories}
								countries={countries}
								contexts={contexts}
							/>
						</div>
						{showEarningsPerReferrer && (
							<div>
								<GameDeveloperEarningsPerReferrerChartModule
									game={game}
									startDate={startDate}
									endDate={endDate}
									deviceCategories={deviceCategories}
									countries={countries}
									contexts={contexts}
								/>
							</div>
						)}
					</StyledGridContainer>
				</>
			)}
		</>
	);
};

const GameOverviewSubPage = props => {
	const { game } = props;

	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();

	const { pending } = useSelectApiStatus(getGameById.id);

	if (game?.annotations?.license_fee === 'yes' && !checkPermissions(permissions, [['can_see_all_clickhouse_data']])) {
		throw new UnauthorizedError();
	}

	// Don't show the graphs if the game isn't released.
	const showGraphs = isReleasedForDeveloper(game);

	return (
		<>
			{game?.content_metadata?.family_blocked && (
				<MessageBox
					id="games-disabled-in-uk-message"
					persistHide
					title={_`gamesDisabledInUK`}
					description={(
						<span dangerouslySetInnerHTML={{ __html: _`gamesDisabledInUKDesc${{ gameTitle: game.title }}` }} />
					)}
				/>
			)}
			<Helmet key={`GameOverviewSubPage-${game.id}-${activeTeam.code}`}>
				<title>{`${game.title} - ${activeTeam.name} - Poki for Developers`}</title>
			</Helmet>
			{!pending && showGraphs ? (
				<ChartsAndGraphs game={game} />
			) : (
				game.approved ? (
					<EmptyMessage
						type="page"
						desc={_`gameEmptyStateDesc`}
					/>
				) : (
					<GameOverviewChecklistModule game={game} />
				)
			)}
		</>
	);
};

export default GameOverviewSubPage;
