import React, { useCallback, useEffect, useState } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import { Subject, ignoreElements, map, merge, tap } from 'rxjs';
import styled from 'styled-components';

import { getRevenueShareAddendumPerTeam } from 'app/src/epics/client';
import { useSelectUser } from 'app/src/selectors/user';
import { registerModal } from 'app/src/modals';
import { patchTeam } from 'app/src/epics/team';

import Modal, { EXIT_SOURCES } from 'app/src/components/ui/Modal';
import Button from 'app/src/components/ui/Button';
import TableLoader from 'app/src/components/ui/TableLoader';

import { _ultraTiny } from 'shared/vars';
import downloadFromBase64 from '../../utils/downloadFromBase64';

const AgreeButton = styled(Button)`	
	margin-top: 20px;
	float: right;
	width: fit-content;

	${_ultraTiny} {
		max-width: 100%;
	}
`;

const PreviewContainer = styled.div`
	position: relative;
	width: 100%;
	height: 60vh;
	min-height: 300px;
	border: 1px solid ${props => props.theme.grey7};
	border-radius: 4px;
	overflow: hidden;
`;

const Preview = styled.iframe`
	width: 100%;
	height: 100%;
`;

const StyledTableLoader = styled(TableLoader)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const RevenueShareAddendumModal = () => {
	const dispatch = useDispatch();

	const [exit$] = useState(new Subject());
	const [confirmCountdown, setConfirmCountdown] = useState(5);
	const [pdf, setPDF] = useState(null);

	const user = useSelectUser();
	const patchTeamStatus = useSelectApiStatus(patchTeam.id);

	useEffect(() => {
		dispatch(getRevenueShareAddendumPerTeam.fetch({ teamId: user?.team?.id }, ({ success$, error$ }) => merge(
			success$.pipe(
				map(({ payload: { result: { response } } }) => {
					setPDF(response.data.attributes);
				}),
			),
			error$.pipe(
				tap(({ payload: { result: { response: { errors: [err] } } } }) => {
					console.error('log error', err);
				}),
				ignoreElements(),
			),
		)));
	}, [dispatch]);

	useEffect(() => {
		if (confirmCountdown < 0) return;

		const timeout = setTimeout(() => {
			setConfirmCountdown(confirmCountdown - 1);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [confirmCountdown]);

	const onConfirm = useCallback(() => {
		if (confirmCountdown > 0) return;
		if (!user?.team) return;

		dispatch(
			patchTeam.fetch(
				{
					teamId: user.team.id,
					data: {
						read_direct_landing_addendum: 'accepted',
					},
				},
				({ success$ }) => success$.pipe(
					tap(() => {
						downloadFromBase64(pdf.data, pdf.filename);
						exit$.next();
					}),
					ignoreElements(),
				),
			),
		);

		exit$.next();
	}, [user?.team, confirmCountdown > 0]);

	return (
		<Modal
			hideExitButton
			canExit={source => source === EXIT_SOURCES.SUBJECT}
			exit$={exit$}
		>
			<h2>Update to our Partnership Agreement</h2>
			<PreviewContainer>
				{pdf ? (
					<Preview src={`data:application/pdf;base64,${pdf.data}`} width="100%" height="500" title="revenue-share-addendum" />
				) : (
					<StyledTableLoader />
				)}
			</PreviewContainer>
			<AgreeButton
				onClick={onConfirm}
				disabled={patchTeamStatus.pending || confirmCountdown > 0}
			>
				{patchTeamStatus.pending ? 'Accepting...' : confirmCountdown > 0 ? `Please read the above (${confirmCountdown})` : 'Accept & Download'}
			</AgreeButton>
		</Modal>
	);
};

registerModal('revenue-share-addendum', RevenueShareAddendumModal);
