import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus, combinedApiStatus } from '@poki/rx-api';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Navigate } from 'react-router-dom';

import QuestionIcon from 'shared/designTokens/icons/ui/small/QuestionIcon';
import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';

import { getEarningsForTeamId, getGameplaysForTeamId, getTotalEarningsForTeamId, getTotalEarningsPrevPeriodForTeamId, getTotalGameplaysForTeamId, getTotalGameplaysPrevPeriodForTeamId, getTablesLastUpdatedAt } from 'app/src/epics/data';
import { useSelectActiveTeam, useSelectTeamCurrency, useSelectTeamLastAccessedAt, useSelectTeamMustUpdateBillingSettings } from 'app/src/selectors/team';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { useSelectGamesByTeamId } from 'app/src/selectors/game';
import { listGamesByTeamId } from 'app/src/epics/game';
import { useSelectPermissions } from 'app/src/selectors/user';
import { useSelectTheme } from 'app/src/selectors/session';
import { pushEvent } from 'app/src/utils/tracking';
import checkPermissions from 'app/src/utils/checkPermissions';
import getTimeSince from 'app/src/utils/getTimeSince';
import useURLState from 'app/src/hooks/useURLState';
import { isReleasedForDeveloper } from 'app/src/utils/game';

import DateRangeInput from 'app/src/components/input/DateRangeInput';
import EmptyMessage from 'app/src/components/ui/EmptyMessage';
import QuickStats from 'app/src/components/QuickStats';
import Card from 'app/src/components/ui/Card';
import Chart from 'app/src/components/ui/charts/Chart';
import Stat from 'app/src/components/ui/Stat';
import GridContainer from 'app/src/components/ui/GridContainer';
import Container from 'app/src/components/ui/Container';
import TeamNameWithFlag from 'app/src/components/ui/TeamNameWithFlag';
import GamesOverviewModule from 'app/src/components/modules/GamesOverviewModule';
import MessageBox, { MessageBoxIntents } from 'app/src/components/ui/MessageBox';

import { dayMonthYearFormat, earliestMomentInP4D } from 'shared/vars';
import _ from 'shared/copy';

const TeamLastAccessedAt = styled.div`
	margin-top: auto;
	text-align: center;
	font-size: 14px;
	color: ${props => props.theme.grey3};
`;

const Filters = styled.div`
	display: flex;
	justify-content: flex-end;

	@media (max-width: 550px) {
		justify-content: flex-start;
	}
`;

const ChartsAndGraphs = props => {
	const { team, games } = props;

	const dispatch = useDispatch();
	const theme = useSelectTheme();

	const [endDate, setEndDate] = useURLState(
		'endDate',
		(() => {
			const date = moment().tz('Europe/Amsterdam');
			date.subtract(1, 'day');
			date.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
			return date;
		})(),
	);

	const [startDate, setStartDate] = useURLState(
		'startDate',
		(() => {
			const date = moment().tz('Europe/Amsterdam');
			date.subtract(30, 'days');
			date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			return date;
		})(),
	);

	const listGamesByTeamIdStatus = useSelectApiStatus(listGamesByTeamId.id);
	const getGameplaysForTeamIdStatus = useSelectApiStatus(getGameplaysForTeamId.id);
	const getTotalEarningsForTeamIdStatus = useSelectApiStatus(getTotalEarningsForTeamId.id);
	const getTotalEarningsPrevPeriodForTeamIdStatus = useSelectApiStatus(getTotalEarningsPrevPeriodForTeamId.id);
	const getEarningsForTeamIdStatus = useSelectApiStatus(getEarningsForTeamId.id);
	const getTotalGameplaysForTeamIdStatus = useSelectApiStatus(getTotalGameplaysForTeamId.id);
	const getTotalGameplaysPrevPeriodForTeamIdStatus = useSelectApiStatus(getTotalGameplaysPrevPeriodForTeamId.id);

	const { rows: gameplays } = useSelectDataByEpic(getGameplaysForTeamId.id);
	const { rows: earnings } = useSelectDataByEpic(getEarningsForTeamId.id);
	const { rows: totalEarnings } = useSelectDataByEpic(getTotalEarningsForTeamId.id);
	const { rows: totalEarningsPrevPeriod } = useSelectDataByEpic(getTotalEarningsPrevPeriodForTeamId.id);
	const { rows: totalGameplays } = useSelectDataByEpic(getTotalGameplaysForTeamId.id);
	const { rows: totalGameplaysPrevPeriod } = useSelectDataByEpic(getTotalGameplaysPrevPeriodForTeamId.id);
	const gameplaysLastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_gameplays');
	const earningsLastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_developer_earnings');

	const teamCurrency = useSelectTeamCurrency(team);

	useEffect(() => {
		if (!games || !team) return;

		dispatch(getTablesLastUpdatedAt.fetch());

		// Gameplays
		dispatch(
			getGameplaysForTeamId.fetch({
				teamId: team.id,
				from: startDate,
				to: endDate,
			}),
		);

		dispatch(
			getTotalGameplaysForTeamId.fetch({
				teamId: team.id,
				from: startDate,
				to: endDate,
			}),
		);

		dispatch(
			getTotalGameplaysPrevPeriodForTeamId.fetch({
				teamId: team.id,
				from: startDate,
				to: endDate,
			}),
		);

		// Earnings
		dispatch(
			getEarningsForTeamId.fetch({
				teamId: team.id,
				from: startDate,
				to: endDate,
				currency: teamCurrency,
			}),
		);

		dispatch(
			getTotalEarningsForTeamId.fetch({
				teamId: team.id,
				from: startDate,
				to: endDate,
				currency: teamCurrency,
			}),
		);

		dispatch(
			getTotalEarningsPrevPeriodForTeamId.fetch({
				teamId: team.id,
				from: startDate,
				to: endDate,
				currency: teamCurrency,
			}),
		);
	}, [games?.length, team, startDate, endDate]);

	const gameplaysStatus = combinedApiStatus(listGamesByTeamIdStatus, getGameplaysForTeamIdStatus, getTotalGameplaysForTeamIdStatus, getTotalGameplaysPrevPeriodForTeamIdStatus);
	const earningsStatus = combinedApiStatus(listGamesByTeamIdStatus, getEarningsForTeamIdStatus, getTotalEarningsForTeamIdStatus, getTotalEarningsPrevPeriodForTeamIdStatus);

	return (
		<>
			<QuickStats />
			<Filters>
				<DateRangeInput
					allTimeStartDate={moment.unix(team.created_at)}
					minDate={earliestMomentInP4D}
					maxDate={moment.utc().subtract(1, 'day')}
					value={[startDate.format(dayMonthYearFormat), endDate.format(dayMonthYearFormat)]}
					valueSetter={([_startDate, _endDate]) => {
						setStartDate(_startDate);
						setEndDate(_endDate);
					}}
					format={dayMonthYearFormat}
					prefix={`${_`dateRange`}:`}
					light
					onChange={range => {
						pushEvent('dashboard', 'changeDateRange', {
							days: moment(range[1]).diff(moment(range[0]), 'days') + 1,
						});
					}}
				/>
			</Filters>
			<GridContainer cols={2} responsive>
				<Card
					title={_`gameplays`}
					buttons={[
						{
							id: 'dashboard-gameplays-metric-explanation',
							type: 'info',
							icon: QuestionIcon,
							children: _`gameplayMetricExplanation`,
						},
						{
							id: 'dashboard-gameplays-last-updated',
							type: 'info',
							disabled: !gameplaysLastUpdatedAt,
							icon: GraphIcon,
							children: _`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(gameplaysLastUpdatedAt)) }}`,
						},
					]}
				>
					{gameplaysStatus.done && (
						<Stat
							value={Math.round(totalGameplays[0]?.gameplays || 0)}
							valueDesc={_`gameplaysThisPeriod`}
							previous={Math.round(totalGameplaysPrevPeriod[0]?.gameplays || 0)}
							previousDesc={_`fromPreviousPeriod`}
						/>
					)}
					<Chart
						type="bar"
						stacked
						legend
						apiStatus={gameplaysStatus}
						data={gameplays}
						getGroupName={d => {
							switch (d.device_category) {
								case 'desktop':
									return _`desktop`;

								case 'mobile':
									return _`mobile`;

								case 'tablet':
									return _`tablet`;

								default:
									return d.device_category;
							}
						}}
						getGroupedBy={d => d.device_category}
						keyOrder={['desktop', 'mobile', 'tablet']}
						getGroupColor={v => {
							if (v === 'mobile') {
								return theme.dataBlue3;
							} else if (v === 'tablet') {
								return theme.dataBlue1;
							}

							return theme.dataBlue5;
						}}
						xRange={[startDate, endDate]}
						xAxis={{
							type: 'date',
							displayName: _`date`,
							field: 'date',
						}}
						yAxis={{
							displayName: _`gameplays`,
							field: 'gameplays',
						}}
					/>
				</Card>
				<Card
					title={_`earnings`}
					buttons={[
						{
							id: 'dashboard-earnings-metric-explanation',
							type: 'info',
							disabled: !earningsLastUpdatedAt,
							icon: QuestionIcon,
							children: _`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(earningsLastUpdatedAt)) }}`,
						},
					]}
				>
					{earningsStatus.done && (
						<Stat
							value={totalEarnings[0]?.developer_earnings || 0}
							valueDesc={_`earnedThisPeriod`}
							previous={totalEarningsPrevPeriod[0]?.developer_earnings || 0}
							previousDesc={_`fromPreviousPeriod`}
							isCurrency={teamCurrency}
						/>
					)}
					<Chart
						type="bar"
						stacked
						legend
						apiStatus={earningsStatus}
						data={earnings}
						getGroupName={d => {
							switch (d.device_category) {
								case 'desktop':
									return _`desktop`;

								case 'mobile':
									return _`mobile`;

								case 'tablet':
									return _`tablet`;

								default:
									return d.device_category;
							}
						}}
						getGroupedBy={d => d.device_category}
						keyOrder={['desktop', 'mobile', 'tablet']}
						getGroupColor={v => {
							if (v === 'mobile') {
								return theme.dataGreen3;
							} else if (v === 'tablet') {
								return theme.dataGreen1;
							}

							return theme.dataGreen5;
						}}
						xRange={[startDate, endDate]}
						xAxis={{
							type: 'date',
							displayName: _`date`,
							field: 'date',
						}}
						yAxis={{
							type: teamCurrency,
							displayName: _`earnings`,
							field: 'developer_earnings',
						}}
					/>
				</Card>
			</GridContainer>

			{games?.length > 1 && (
				<GridContainer cols={1}>
					<GamesOverviewModule startDate={startDate} endDate={endDate} team={team} />
				</GridContainer>
			)}
		</>
	);
};

const DashboardPage = () => {
	const dispatch = useDispatch();

	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();
	const teamCurrency = useSelectTeamCurrency(activeTeam);
	const teamLastAccessedAt = useSelectTeamLastAccessedAt(activeTeam?.code);
	const mustUpdateBillingSettings = useSelectTeamMustUpdateBillingSettings(activeTeam?.code);

	const { data: games = [] } = useSelectGamesByTeamId(activeTeam?.id);
	const { rows: gameplays } = useSelectDataByEpic(getGameplaysForTeamId.id);
	const { rows: earnings } = useSelectDataByEpic(getEarningsForTeamId.id);

	const listGamesByTeamIdStatus = useSelectApiStatus(listGamesByTeamId.id);

	const canSeeDashboard = useMemo(() => {
		if (!gameplays.length && !earnings.length) {
			return false;
		}

		return true;
	}, [gameplays, earnings]);

	const canHaveLiveGames = useMemo(() => {
		if (!games) return false;

		return games.some(game => isReleasedForDeveloper(game));
	}, [games]);

	const canReadDashboards = checkPermissions(permissions, [['can_read_dashboards']]);

	useEffect(() => {
		if (!activeTeam || !canReadDashboards) return;

		const startDate = moment().tz('Europe/Amsterdam').subtract(30, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
		const endDate = moment().tz('Europe/Amsterdam').subtract(1, 'day').set({ hour: 23, minute: 59, second: 59, millisecond: 59 });

		dispatch(
			getGameplaysForTeamId.fetch({
				teamId: activeTeam.id,
				from: startDate,
				to: endDate,
			}),
		);

		dispatch(
			getEarningsForTeamId.fetch({
				teamId: activeTeam.id,
				from: startDate,
				to: endDate,
				currency: teamCurrency,
			}),
		);
	}, [activeTeam]);

	useEffect(() => {
		dispatch(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
	}, [activeTeam]);

	if (listGamesByTeamIdStatus.done && !activeTeam.verified) {
		if (games.length === 0) {
			return (
				<Navigate
					replace
					to={`/${activeTeam.code}/games/create`}
				/>
			);
		} else {
			return (
				<Navigate
					replace
					to={`/${activeTeam.code}/games/${games[0].id}`}
				/>
			);
		}
	}

	return (
		<Container>
			<Helmet key={`DashboardPage-${activeTeam?.code}`}>
				<title>Dashboard {activeTeam?.name ? `- ${activeTeam.name}` : ''} - Poki for Developers</title>
			</Helmet>
			{mustUpdateBillingSettings && (
				<MessageBox
					id="must-update-billing-settings"
					unclosable
					intent={MessageBoxIntents.NEGATIVE}
					title={_`mustUpdateBillingSettingsTitle`}
					description={(
						<span dangerouslySetInnerHTML={{ __html: _`mustUpdateBillingSettingsMessage` }} />
					)}
				/>
			)}
			{(canHaveLiveGames && canSeeDashboard) ? (
				<>
					<MessageBox
						id="welcome-to-your-dashboard"
						persistHide
						title={_`welcomeTitle`}
						description={(
							<span dangerouslySetInnerHTML={{ __html: _`welcomeDescription` }} />
						)}
					/>
					<ChartsAndGraphs
						team={activeTeam}
						games={games}
					/>
				</>
			) : (
				<EmptyMessage
					type="page"
					title={_`welcomeToYourDashboard`}
					desc={_`dashboardEmptyStateDesc`}
				/>
			)}
			{checkPermissions(permissions, [['can_read_team_last_accessed_by']]) && (
				<TeamLastAccessedAt>
					<TeamNameWithFlag team={activeTeam} /> accessed P4D last on <strong>{teamLastAccessedAt ? moment(teamLastAccessedAt * 1000).format(dayMonthYearFormat) : _`never`}</strong>
				</TeamLastAccessedAt>
			)}
		</Container>
	);
};

export default DashboardPage;
