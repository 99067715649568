import React from 'react';
import { Helmet } from 'react-helmet';

import { useSelectActiveTeam } from 'app/src/selectors/team';

import GameGeneralSettingsModule from 'app/src/components/modules/GameGeneralSettingsModule';
import GameBeaconSnippetModule from 'app/src/components/modules/GameBeaconSnippetModule';
import GameUploadTokenModule from 'app/src/components/modules/GameUploadTokenModule';
import GridContainer from 'app/src/components/ui/GridContainer';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';

const GameSettingsSubPage = props => {
	const { game } = props;

	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();

	return (
		<GridContainer cols={1}>
			<Helmet key={`GameSettingsSubPage-${game.id}-${activeTeam.code}`}>
				<title>Settings - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<GameGeneralSettingsModule game={game} />
			{activeTeam.verified && (
				<>
					{checkPermissions(permissions, [['can_generate_upload_token']]) && <GameUploadTokenModule game={game} />}
					{checkPermissions(permissions, [['can_edit_owned_versions']]) && <GameBeaconSnippetModule game={game} />}
				</>
			)}
		</GridContainer>
	);
};

export default GameSettingsSubPage;
