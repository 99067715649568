import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { getEarningsForGameId, getTablesLastUpdatedAt } from 'app/src/epics/data';
import { createGetTotalEarningsForGameId } from 'app/src/epicCreators/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { useSelectTeamCurrency } from 'app/src/selectors/team';
import { useSelectTheme } from 'app/src/selectors/session';
import getTimeSince from 'app/src/utils/getTimeSince';
import useComponentId from 'app/src/hooks/useComponentId';
import useDataEpic from 'app/src/hooks/useDataEpic';

import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';

import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';
import Chart from 'app/src/components/ui/charts/Chart';

import _ from 'shared/copy';

const GameDeveloperEarningsChartModule = props => {
	const { game, startDate, endDate, deviceCategories, contexts, countries } = props;

	const componentId = useComponentId();

	const dispatch = useDispatch();
	const theme = useSelectTheme();

	const teamCurrency = useSelectTeamCurrency(game?.team);

	const getTotalEarningsForGameId = useDataEpic(createGetTotalEarningsForGameId(componentId), []);
	const getTotalEarningsPrevPeriodForGameId = useDataEpic(createGetTotalEarningsForGameId(`prev-${componentId}`), []);

	useEffect(() => {
		if (!game) return;

		const options = {
			gameId: game.id,
			teamId: game.team_id,
			from: startDate,
			to: endDate,
			currency: teamCurrency,
			deviceCategories,
			countries,
			contexts,
		};

		dispatch(getTablesLastUpdatedAt.fetch());

		dispatch(getEarningsForGameId.fetch(options));
		dispatch(getTotalEarningsForGameId.fetch(options));

		// Previous period
		const fromMoment = moment.utc(startDate);
		const toMoment = moment.utc(endDate);
		const dayDiff = toMoment.diff(fromMoment, 'day') + 1;
		const prevPeriodFrom = fromMoment.clone().subtract(dayDiff, 'day');
		const prevPeriodTo = toMoment.clone().subtract(dayDiff, 'day');

		dispatch(getTotalEarningsPrevPeriodForGameId.fetch({
			...options,
			from: prevPeriodFrom,
			to: prevPeriodTo,
		}));
	}, [game?.id, startDate, endDate, deviceCategories, countries, contexts]);

	const getEarningsForGameIdStatus = useSelectApiStatus(getEarningsForGameId.id);
	const getTotalEarningsForGameIdStatus = useSelectApiStatus(getTotalEarningsForGameId.id);
	const getTotalEarningsPrevPeriodForGameIdStatus = useSelectApiStatus(getTotalEarningsPrevPeriodForGameId.id);

	const apiStatus = combinedApiStatus(getEarningsForGameIdStatus, getTotalEarningsForGameIdStatus, getTotalEarningsPrevPeriodForGameIdStatus);

	const earningsForGame = useSelectDataByEpic(getEarningsForGameId.id).rows;
	const [totalEarnings] = useSelectDataByEpic(getTotalEarningsForGameId.id).rows;
	const [totalEarningsPrevPeriod] = useSelectDataByEpic(getTotalEarningsPrevPeriodForGameId.id).rows;

	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_developer_earnings');

	return (
		<Card
			title={_`earnings`}
			buttons={[
				{
					id: 'earnings-last-updated',
					type: 'info',
					disabled: !lastUpdatedAt,
					icon: GraphIcon,
					children: _`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`,
				},
			]}
		>
			{apiStatus.done && (
				<Stat
					value={totalEarnings?.developer_earnings || 0}
					valueDesc={_`earnedThisPeriod`}
					previous={totalEarningsPrevPeriod?.developer_earnings || 0}
					previousDesc={_`fromPreviousPeriod`}
					isCurrency={teamCurrency}
				/>
			)}
			<Chart
				type="bar"
				legend
				stacked
				apiStatus={apiStatus}
				data={earningsForGame}
				getGroupedBy={d => d.device_category}
				getGroupName={d => {
					switch (d.device_category) {
						case 'desktop':
							return _`desktop`;

						case 'mobile':
							return _`mobile`;

						case 'tablet':
							return _`tablet`;

						default:
							return d.device_category;
					}
				}}
				keyOrder={['desktop', 'mobile', 'tablet']}
				getGroupColor={v => {
					if (v === 'mobile') {
						return theme.dataGreen3;
					} else if (v === 'tablet') {
						return theme.dataGreen1;
					}

					return theme.dataGreen5;
				}}
				xRange={[startDate, endDate]}
				xAxis={{
					type: 'date',
					displayName: _`date`,
					field: 'date',
				}}
				yAxis={{
					type: teamCurrency,
					displayName: _`earnings`,
					field: 'developer_earnings',
				}}
			/>
		</Card>
	);
};

export default GameDeveloperEarningsChartModule;
