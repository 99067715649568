import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import styled from 'styled-components';

import { getErrorsForGame, getErrorGameplaysForGame } from 'app/src/epics/data';
import { useSelectDataByEpic } from 'app/src/selectors/data';
import ErrorDetails from 'app/src/components/ErrorDetails';
import formatNumber from 'app/src/utils/formatNumber';
import useURLState from 'app/src/hooks/useURLState';
import Table from 'app/src/components/ui/Table';
import _ from 'shared/copy';
import { setInitialErrorsDetailsPagination } from 'app/src/actions/session';

const ErrorLine = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	${props => props.isExpanded && `
	display: flex;
	flex-direction: column;
	white-space: normal;
	`}
`;

const ErrorName = styled.span`
	font-weight: bold;
	margin-right: 8px;
`;

const Details = styled.span`
	color: ${props => props.theme.grey3};
`;

const errorsPerPage = 50;

const ErrorDetailsModule = props => {
	const { game, team, to, from, activeFilters, filters, setActiveFilters } = props;

	const dispatch = useDispatch();

	const [sort, setSort] = useURLState('sort', { field: 'gameplays', direction: -1 });
	const [page, setPage] = useState('page', 1);

	const { rows: errors, total: totalErrors } = useSelectDataByEpic(getErrorsForGame.id);
	const { rows: totalAffectedGameplays } = useSelectDataByEpic(getErrorGameplaysForGame.id);

	const getErrorsforGameStatus = useSelectApiStatus(getErrorsForGame.id);

	useEffect(() => {
		if (!game) return;

		dispatch(getErrorsForGame.fetch({ gameId: game.id, teamId: game.team.id, from, to, sortField: sort.field, sortDirection: sort.direction, page, perPage: errorsPerPage, filters: activeFilters }));
		dispatch(getErrorGameplaysForGame.fetch({ gameId: game.id, teamId: game.team.id, from, to, sortField: sort.field, sortDirection: sort.direction, page, perPage: errorsPerPage }));
	}, [sort, page, game, activeFilters]);

	useEffect(() => {
		if (errors) {
			const listOfErrorsIDs = {};

			errors.map(e => Object.assign(listOfErrorsIDs, { [e.error_id]: 1 }));
			dispatch(setInitialErrorsDetailsPagination({ errors: listOfErrorsIDs }));
		}
	}, [errors]);

	const getPercentageOfAffectedGameplays = ({ gameplays, affectedGameplays }) => {
		if (gameplays > affectedGameplays) return '100%';

		const totalAffected = formatNumber((gameplays / affectedGameplays) * 100);

		if (totalAffected < 1) {
			return '< 1%';
		}

		return `${totalAffected}%`;
	};

	return (
		<Table
			isLoading={game.content_metadata ? !getErrorsforGameStatus.done : false}
			items={errors}
			compact
			details={({ item }) => (
				<ErrorDetails
					game={game}
					error={item}
					activeFilters={activeFilters}
					team={team}
					from={from}
					to={to}
				/>
			)}
			setSort={setSort}
			sortField={sort.field}
			sortDirection={sort.direction}
			setPagination={setPage}
			page={page}
			perPage={errorsPerPage}
			totalItems={totalErrors}
			filters={filters}
			activeFilters={activeFilters}
			setActiveFilters={setActiveFilters}
			columns={[
				{
					title: _`gameplays`,
					width: '150px',
					content: ({ item }) => <>{formatNumber(item.gameplays)}</>,
					sortField: 'gameplays',
					isDefaultSort: true,
				},
				{
					title: _`estimatedPercentage`,
					longTitle: _`estimatedPercentageOfGameplays`,
					width: '160px',
					content: ({ item }) => <>{getPercentageOfAffectedGameplays({ gameplays: item?.gameplays, affectedGameplays: totalAffectedGameplays[0]?.gameplays })}</>,
					sortField: 'gameplays',
					isDefaultSort: true,
				},
				{
					title: _`totalErrors`,
					width: '150px',
					content: ({ item }) => <>{formatNumber(item.total_errors)}</>,
					sortField: 'total_errors',
				},
				{
					title: _`error`,
					content: ({ item, itemDetailsActive }) => (
						<ErrorLine isExpanded={itemDetailsActive}>
							<ErrorName>{item.error_name ? `${item.error_name}${item.error_message ? ': ' : ''}` : ''}{item.error_message}</ErrorName>
							{item.stack_line !== '{}' ? <Details>{item.stack_line}</Details> : ''}
						</ErrorLine>
					),
				},
			]}
		/>
	);
};

export default ErrorDetailsModule;
