import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { createGetTotalGameplaysPerReferrerForGameId } from 'app/src/epicCreators/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { getGameplaysPerReferrerForGameId, getTablesLastUpdatedAt } from 'app/src/epics/data';
import { useSelectTheme } from 'app/src/selectors/session';
import useComponentId from 'app/src/hooks/useComponentId';
import getTimeSince from 'app/src/utils/getTimeSince';
import useDataEpic from 'app/src/hooks/useDataEpic';
import { ChartDetails, ChartDetailsInner, DetailNumber } from 'app/src/components/ChartSubComponents';

import Chart from 'app/src/components/ui/charts/Chart';
import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';

import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';

import formatNumber from 'shared/utils/formatNumber';
import _ from 'shared/copy';

const GameGameplaysPerReferrerChartModule = props => {
	const { game, startDate, endDate, deviceCategories, countries, contexts, versions } = props;

	const componentId = useComponentId();

	const getTotalGameplaysForGameId = useDataEpic(createGetTotalGameplaysPerReferrerForGameId(componentId), []);
	const getTotalGameplaysPrevPeriodForGameId = useDataEpic(createGetTotalGameplaysPerReferrerForGameId(`prev-${componentId}`), []);

	const dispatch = useDispatch();
	const theme = useSelectTheme();

	useEffect(() => {
		if (!game) return;

		const options = {
			gameId: game.id,
			teamId: game.team_id,
			from: startDate,
			to: endDate,
			deviceCategories,
			countries,
			contexts,
			versions,
		};

		dispatch(getTablesLastUpdatedAt.fetch());

		dispatch(getGameplaysPerReferrerForGameId.fetch(options));
		dispatch(getTotalGameplaysForGameId.fetch(options));

		// Previous period
		const fromMoment = moment.utc(startDate);
		const toMoment = moment.utc(endDate);
		const dayDiff = toMoment.diff(fromMoment, 'day') + 1;
		const prevPeriodFrom = fromMoment.clone().subtract(dayDiff, 'day');
		const prevPeriodTo = toMoment.clone().subtract(dayDiff, 'day');

		dispatch(getTotalGameplaysPrevPeriodForGameId.fetch({
			...options,
			from: prevPeriodFrom,
			to: prevPeriodTo,
		}));
	}, [game?.id, startDate, endDate, deviceCategories, countries, contexts, versions]);

	const getGameplaysPerReferrerForGameIdStatus = useSelectApiStatus(getGameplaysPerReferrerForGameId.id);
	const getTotalGameplaysForGameIdStatus = useSelectApiStatus(getTotalGameplaysForGameId.id);
	const getTotalGameplaysPrevPeriodForGameIdStatus = useSelectApiStatus(getTotalGameplaysPrevPeriodForGameId.id);

	const apiStatus = combinedApiStatus(getGameplaysPerReferrerForGameIdStatus, getTotalGameplaysForGameIdStatus, getTotalGameplaysPrevPeriodForGameIdStatus);

	const gameplaysForGame = useSelectDataByEpic(getGameplaysPerReferrerForGameId.id).rows;
	const [totalGameplays] = useSelectDataByEpic(getTotalGameplaysForGameId.id).rows;
	const [totalGameplaysPrevPeriod] = useSelectDataByEpic(getTotalGameplaysPrevPeriodForGameId.id).rows;

	const totalGameplaysPerReferrer = useMemo(() => {
		if (!gameplaysForGame) return null;

		return gameplaysForGame.reduce((acc, row) => {
			const referrerGroup = row.referrer_group;

			if (!acc[referrerGroup]) {
				acc[referrerGroup] = 0;
			}

			acc[referrerGroup] += row.gameplays;

			return acc;
		}, {});
	}, [gameplaysForGame]);

	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_gameplays');

	return (
		<Card
			title={_`gameplays`}
			buttons={[
				{
					id: 'gameplays-last-updated-referrer',
					type: 'info',
					disabled: !lastUpdatedAt,
					icon: GraphIcon,
					children: _`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`,
				},
			]}
		>
			{apiStatus.done && (
				<Stat
					value={Math.round(totalGameplays?.gameplays || 0)}
					valueDesc={_`gameplaysThisPeriod`}
					previous={Math.round(totalGameplaysPrevPeriod?.gameplays || 0)}
					previousDesc={_`fromPreviousPeriod`}
				/>
			)}
			<Chart
				type="bar"
				legend
				stacked
				apiStatus={apiStatus}
				data={gameplaysForGame}
				getGroupedBy={d => d.referrer_group}
				getGroupName={d => {
					switch (d.referrer_group) {
						case 'developer_domain':
							return _`developerDomain`;

						case 'organic_landing':
							return _`organicLandings`;

						case 'poki_promotion':
							return _`pokiPromotions`;

						default:
							return d.referrer_group;
					}
				}}
				keyOrder={['organic_landing', 'developer_domain', 'poki_promotion']}
				getGroupColor={v => {
					if (v === 'developer_domain') {
						return theme.dataPurple3;
					} else if (v === 'organic_landing') {
						return theme.dataPurple5;
					}

					return theme.dataYellow3;
				}}
				xRange={[startDate, endDate]}
				xAxis={{
					type: 'date',
					displayName: _`date`,
					field: 'date',
				}}
				yAxis={{
					displayName: _`gameplays`,
					field: 'gameplays',
				}}
			/>
			<ChartDetails>
				{apiStatus.done && totalGameplaysPerReferrer && (
					<ChartDetailsInner>
						{
							totalGameplaysPerReferrer.developer_domain > 0 ? (
								_`gameplaysPerTrafficSourceWhatDoesThisMean${{
									organicLandingGameplays: <DetailNumber $color={theme.dataPurple5}>{formatNumber(totalGameplaysPerReferrer.organic_landing, { shorten: true })}</DetailNumber>,
									developerDomainGameplays: <DetailNumber $color={theme.dataPurple3}>{formatNumber(totalGameplaysPerReferrer.developer_domain, { shorten: true })}</DetailNumber>,
									pokiPromotionGameplays: <DetailNumber $color={theme.dataYellow3}>{formatNumber(totalGameplaysPerReferrer.poki_promotion, { shorten: true })}</DetailNumber>,
								}}`
							) : (
								_`gameplaysPerTrafficSourceNoDeveloperDomainWhatDoesThisMean${{
									organicLandingGameplays: <DetailNumber $color={theme.dataPurple5}>{formatNumber(totalGameplaysPerReferrer.organic_landing, { shorten: true })}</DetailNumber>,
									pokiPromotionGameplays: <DetailNumber $color={theme.dataYellow3}>{formatNumber(totalGameplaysPerReferrer.poki_promotion, { shorten: true })}</DetailNumber>,
								}}`
							)
						}
					</ChartDetailsInner>
				)}
			</ChartDetails>
		</Card>
	);
};

export default GameGameplaysPerReferrerChartModule;
