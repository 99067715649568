import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Subject, tap, ignoreElements } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';

import { listGamesByTeamId } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import Button from 'app/src/components/ui/Button';
import AutoCompleteInput from 'app/src/components/input/AutoCompleteInput';
import TextInput from 'app/src/components/input/TextInput';
import Modal from 'app/src/components/ui/Modal';
import { validate, isRequired } from 'app/src/utils/validate';
import { registerModal } from 'app/src/modals';
import { useSelectNewRevenueSharesByTeamId, useSelectTeamByCode, useSelectTeamCurrency, useSelectTeamsList } from 'app/src/selectors/team';
import DateInput from 'app/src/components/input/DateInput';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import _ from 'shared/copy';
import { useSelectGamesByTeamId } from 'app/src/selectors/game';
import GridContainer from 'app/src/components/ui/GridContainer';
import { createOneOffPaymentForTeamId } from 'app/src/epics/additionalPayments';
import moment from 'moment';
import SelectInput from 'app/src/components/input/SelectInput';
import { getTeamNewRevenueShares, listTeams } from 'app/src/epics/team';
import RadioInput from 'app/src/components/input/RadioInput';
import { hasNonZeroShare } from 'app/src/utils/revenueShares';

const Form = styled.form`
	width: 100%;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const InternalNotesTextArea = styled(TextAreaInput)`
	max-width: 100%;
`;

const AddOneOffPaymentModal = props => {
	const { data: { teamCode } } = props;

	const team = useSelectTeamByCode(teamCode);

	const dispatch = useDispatch();
	const formRef = useRef();
	const [errors, setErrors] = useState({});
	const listGamesByTeamIdStatus = useSelectApiStatus(listGamesByTeamId.id);
	const listTeamsStatus = useSelectApiStatus(listTeams.id);
	const createOneOffPaymentForTeamIdStatus = useSelectApiStatus(createOneOffPaymentForTeamId.id);
	const [exit$] = useState(new Subject());

	const [amount, setAmount] = useState('');
	const [type, setType] = useState();
	const [sendDate, setSendDate] = useState();
	const [internalNotes, setInternalNotes] = useState();
	const [gameIds, setGameIds] = useState([]);
	const [targetTeamId, setTargetTeamId] = useState();
	const [separateInvoice, setSeparateInvoice] = useState(1);
	const revenueShares = useSelectNewRevenueSharesByTeamId(team.id);
	const hasActiveRevenueShares = hasNonZeroShare(revenueShares);

	const validatorData = { // Data used by validators to determine which fields need validation
		type,
	};

	const { data: games = [] } = useSelectGamesByTeamId(team?.id);
	const teams = useSelectTeamsList();
	const teamCurrency = useSelectTeamCurrency(team);

	// While typing, only allow numbers and dots
	const setPreformatAmount = value => {
		if (!value) {
			setAmount('');
			return;
		}

		setAmount(value.replace(/[^0-9.]+/g, ''));
	};

	// Ensure we have a valid currency number at the end (e.g. not 32.1.5.6)
	const ensureValidCurrencyAmount = () => {
		const numValue = Number(amount);
		if (!Number.isNaN(numValue)) {
			const numParts = String(numValue).split('.');
			if (numParts.length === 2) {
				const withDecimals = numValue.toFixed(2);
				if (withDecimals.split('.').pop() !== '00') {
					setAmount(withDecimals);
				} else {
					setAmount(numParts[0]);
				}
			} else {
				setAmount(numValue);
			}
		} else {
			setAmount('');
		}
	};

	useEffect(() => {
		dispatch(listGamesByTeamId.fetch({ teamId: team.id }));
		dispatch(listTeams.fetch());
		dispatch(getTeamNewRevenueShares.fetch({ teamId: team.id }));
	}, [team?.id]);

	const handleSubmit = evt => {
		evt.preventDefault();

		const err = { ...errors };

		const checks = [
			{
				field: 'oneOffPayment_type',
				value: type,
			},
			{
				field: 'oneOffPayment_team',
				value: team,
			},
			{
				field: 'oneOffPayment_amount',
				value: amount,
			},
			{
				field: 'oneOffPayment_sendDate',
				value: sendDate,
			},
			{
				field: 'oneOffPayment_internalNotes',
				value: internalNotes,
			},
			{
				field: 'oneOffPayment_games',
				value: gameIds,
			},
			{
				field: 'oneOffPayment_targetTeam',
				value: targetTeamId,
			},
			{
				field: 'oneOffPayment_separateInvoice',
				value: separateInvoice,
			},
		];

		checks.forEach(c => {
			const check = validate(c.field, c.value, validatorData);

			if (!check.valid) {
				err[c.field] = check.messages;
			} else {
				delete err[c.field];
			}
		});

		setErrors(err);

		if (Object.keys(err).length > 0) {
			dispatch(startScreenShake());
			return;
		}

		dispatch(createOneOffPaymentForTeamId.fetch({ type, teamId: team.id, amount, sendDate, internalNotes, gameIds, targetTeamId, separateInvoice: Number(separateInvoice) }, ({ success$ }) => (
			success$.pipe(
				tap(() => exit$.next()),
				ignoreElements(),
			)
		)));
	};

	if (!team) return null;

	return (
		<Modal wide exit$={exit$}>
			<>
				<h2>{_`addOneOffPayment`}</h2>
				<p>{_`addOneOffPaymentDescription`}</p>
				<Form ref={formRef} onSubmit={handleSubmit}>
					<GridContainer cols={2}>
						<TextInput
							label={_`team`}
							disabled
							name="oneOffPayment_team"
							value={team.name}
						/>
					</GridContainer>
					<GridContainer cols={2}>
						<div>
							<SelectInput
								label={_`type`}
								name="oneOffPayment_type"
								disabled={createOneOffPaymentForTeamId.pending}
								errors={errors.oneOffPayment_type}
								value={type}
								valueSetter={setType}
								required={isRequired('oneOffPayment_type')}
								placeholder={_`selectAType`}
								values={[
									{ value: 'contractual-milestone', desc: _`contractualMilestone` },
									{ value: 'license-fee', desc: _`licenseFee` },
									{ value: 'manual-adjustment', desc: _`manualAdjustment` },
									{ value: 'poki-community-ambassador', desc: _`pokiCommunityAmbassador` },
									{ value: 'industry-sponsorship', desc: _`industrySponsorship` },
									{ value: 'production-costs', desc: _`productionCosts` },
									{ value: 'refer-a-friend', desc: _`referAFriend` },
									{ value: 'server-costs', desc: _`serverCosts` },
								]}
							/>
							<TextInput
								label={_`amount`}
								autoFocus
								disabled={createOneOffPaymentForTeamId.pending}
								name="oneOffPayment_amount"
								prefix={teamCurrency === 'usd' ? '$' : '€'}
								placeholder={500}
								errors={errors.oneOffPayment_amount}
								value={amount}
								valueSetter={setPreformatAmount}
								onBlur={ensureValidCurrencyAmount}
								required={isRequired('oneOffPayment_amount')}
							/>
							{(type === 'license-fee' || type === 'production-costs' || type === 'revenue-correction' || type === 'server-costs' || type === 'manual-adjustment') && (
								<AutoCompleteInput
									label={_`game(s)`}
									name="oneOffPayment_games"
									disabled={createOneOffPaymentForTeamId.pending || listGamesByTeamIdStatus.pending}
									values={games.map(game => ({
										value: game.id,
										desc: game.title,
									}))}
									errors={errors.oneOffPayment_games}
									value={gameIds}
									valueSetter={setGameIds}
									required={isRequired('oneOffPayment_games')}
								/>
							)}
							{type === 'refer-a-friend' && (
								<SelectInput
									label={_`referredTeam`}
									name="oneOffPayment_targetTeam"
									disabled={createOneOffPaymentForTeamId.pending || listTeamsStatus.pending}
									values={teams.map(t => ({
										value: t.id,
										desc: t.name,
									}))}
									errors={errors.oneOffPayment_targetTeam}
									value={targetTeamId}
									valueSetter={setTargetTeamId}
									placeholder={_`selectATeam`}
									required={isRequired('oneOffPayment_targetTeam')}
								/>
							)}
						</div>
						<div>
							<DateInput
								label={_`sendDate`}
								name="oneOffPayment_sendDate"
								disabled={createOneOffPaymentForTeamId.pending}
								errors={errors.oneOffPayment_sendDate}
								value={sendDate}
								valueSetter={setSendDate}
								required={isRequired('oneOffPayment_sendDate')}
								minDate={moment.utc().startOf('day')}
							/>
							<RadioInput
								label={_`invoicing`}
								name="oneOffPayment_separateInvoice"
								values={[
									{
										value: 1,
										desc: 'Send as separate invoice on send date',
									},
									{
										value: 0,
										desc: 'Include in next rev share invoice',
										disabled: !hasActiveRevenueShares,
										tooltip: !hasActiveRevenueShares ? {
											title: _`additionalPaymentsRevenueSharesRequired`,
											body: _`additionalPaymentsRevenueSharesRequiredOptionDescription`,
										} : null,
									},
								]}
								value={separateInvoice}
								valueSetter={setSeparateInvoice}
								errors={errors.separateInvoice}
								disabled={createOneOffPaymentForTeamIdStatus.pending}
								required={isRequired('oneOffPayment_separateInvoice')}
							/>
						</div>
					</GridContainer>
					<InternalNotesTextArea
						label={_`internalNotes`}
						name="oneOffPayment_internalNotes"
						disabled={createOneOffPaymentForTeamId.pending}
						description={_`oneOffPaymentNotesDescription`}
						errors={errors.oneOffPayment_internalNotes}
						value={internalNotes}
						valueSetter={setInternalNotes}
						required={isRequired('oneOffPayment_internalNotes')}
					/>
					<Buttons>
						<Button disabled={createOneOffPaymentForTeamId.pending} secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={createOneOffPaymentForTeamIdStatus.pending}>{createOneOffPaymentForTeamIdStatus.pending ? _`creating` : _`create`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('add-one-off-payment', AddOneOffPaymentModal);
