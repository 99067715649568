import { padStart } from 'lodash';

export default function formatTime(num, natural = true, short = false) {
	const input = Math.round(num);

	const days = Math.floor(input / 86400);
	const hours = Math.floor((input % 86400) / 3600);
	const minutes = Math.floor((input % 3600) / 60);
	const seconds = input % 3600 % 60;

	const padDays = padStart(days, 2, 0);
	const padHours = padStart(hours, 2, 0);
	const padMinutes = padStart(minutes, 2, 0);
	const padSeconds = padStart(seconds, 2, 0);

	if (days > 0) {
		if (natural) {
			if (short) {
				if (days > 1) {
					return `${days}d`;
				}
				return `${days}d ${hours}h`;
			}
			return `${days}d ${hours}h${minutes}m${seconds}s`;
		} else if (short) {
			return `${padDays}:${padHours}`;
		}
		return `${padDays}:${padHours}:${padMinutes}:${padSeconds}`;
	}
	if (hours > 0) {
		if (natural) {
			if (short) {
				return `${hours}h ${minutes}m`;
			}
			return `${hours}h${minutes}m${seconds}s`;
		} else if (short) {
			return `${padHours}:${padMinutes}`;
		}
		return `${padHours}:${padMinutes}:${padSeconds}`;
	}

	if (minutes > 0) {
		if (natural) {
			if (short) {
				return `${minutes}m${seconds}s`;
			}
			return `${minutes}m ${seconds}s`;
		}

		return `${padMinutes}:${padSeconds}`;
	}

	if (natural) {
		return `${seconds}s`;
	}

	return `${padMinutes}:${padSeconds}`;
}
